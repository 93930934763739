/* tslint:disable */
/* eslint-disable */
/**
 * Calculate assessment API.
 * API for requesting Assessment via Service Finder Tool
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { RepeatabilityCheckDTO } from './RepeatabilityCheckDTO';
import {
    RepeatabilityCheckDTOFromJSON,
    RepeatabilityCheckDTOFromJSONTyped,
    RepeatabilityCheckDTOToJSON,
} from './RepeatabilityCheckDTO';
import type { OnePointCalibrationDTO } from './OnePointCalibrationDTO';
import {
    OnePointCalibrationDTOFromJSON,
    OnePointCalibrationDTOFromJSONTyped,
    OnePointCalibrationDTOToJSON,
} from './OnePointCalibrationDTO';

/**
 * 
 * @export
 * @interface PageFiveDTO
 */
export interface PageFiveDTO {
    /**
     * 
     * @type {string}
     * @memberof PageFiveDTO
     */
    internalAdjustment: string;
    /**
     * 
     * @type {OnePointCalibrationDTO}
     * @memberof PageFiveDTO
     */
    onePointCalibrationDTO: OnePointCalibrationDTO;
    /**
     * 
     * @type {RepeatabilityCheckDTO}
     * @memberof PageFiveDTO
     */
    repeatabilityCheckDTO?: RepeatabilityCheckDTO;
}

/**
 * Check if a given object implements the PageFiveDTO interface.
 */
export function instanceOfPageFiveDTO(value: object): value is PageFiveDTO {
    if (!('internalAdjustment' in value) || value['internalAdjustment'] === undefined) return false;
    if (!('onePointCalibrationDTO' in value) || value['onePointCalibrationDTO'] === undefined) return false;
    return true;
}

export function PageFiveDTOFromJSON(json: any): PageFiveDTO {
    return PageFiveDTOFromJSONTyped(json, false);
}

export function PageFiveDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): PageFiveDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'internalAdjustment': json['internalAdjustment'],
        'onePointCalibrationDTO': OnePointCalibrationDTOFromJSON(json['onePointCalibrationDTO']),
        'repeatabilityCheckDTO': json['repeatabilityCheckDTO'] == null ? undefined : RepeatabilityCheckDTOFromJSON(json['repeatabilityCheckDTO']),
    };
}

export function PageFiveDTOToJSON(value?: PageFiveDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'internalAdjustment': value['internalAdjustment'],
        'onePointCalibrationDTO': OnePointCalibrationDTOToJSON(value['onePointCalibrationDTO']),
        'repeatabilityCheckDTO': RepeatabilityCheckDTOToJSON(value['repeatabilityCheckDTO']),
    };
}

