import i18next, { TFunction } from "i18next";
import { getTranslationMap } from "./LanguageUtils";
import { Step2Section } from "../types/Step2Section";

export const getSectionsData = (t: TFunction): Array<Step2Section> => {
  let counter = 0;
  const sections: Array<Step2Section> = [];

  while (i18next.exists(`common:step2.sections.${counter}.leftTop`)) {
    const dropdownOptions = getTranslationMap(
      t,
      `step2.sections.${counter}.dropdownOptions`
    );

    sections.push({
      leftBottomText: t(`step2.sections.${counter}.leftBottom`).toString(),
      leftTopText: t(`step2.sections.${counter}.leftTop`).toString(),
      dropdownName: t(`step2.sections.${counter}.dropdownName`).toString(),
      textboxName: i18next.exists(
        `common:step2.sections.${counter}.textboxName`
      )
        ? t(`step2.sections.${counter}.textboxName`).toString()
        : undefined,
      isSearchable: i18next.exists(
        `common:step2.sections.${counter}.isSearchable`
      ),
      textboxPlaceholder: i18next.exists(
        `common:step2.sections.${counter}.textboxPlaceholder`
      )
        ? t(`step2.sections.${counter}.textboxPlaceholder`).toString()
        : undefined,
      searchPlaceholder: i18next.exists(
        `common:step2.sections.${counter}.searchPlaceholder`
      )
        ? t(`step2.sections.${counter}.searchPlaceholder`).toString()
        : undefined,
      defaultDropdownValue: i18next.exists(
        `common:step2.sections.${counter}.defaultDropdownValue`
      )
        ? t(`step2.sections.${counter}.defaultDropdownValue`).toString()
        : undefined,
      dropdownPlaceholder: i18next.exists(
        `common:step2.sections.${counter}.dropdownPlaceholder`
      )
        ? t(`step2.sections.${counter}.dropdownPlaceholder`).toString()
        : undefined,
      dropdownOptions: dropdownOptions.size ? dropdownOptions : undefined,
    });

    counter++;
  }

  return sections;
};
