import {
  CCol,
  CContainer,
  CHeader,
  CHeaderBrand,
  CImg,
  CLink,
  CNav,
  CRow,
  CSubheader,
} from "@coreui/react";
import React from "react";
import sartoriusLogo from "../sartorius-logo.svg";
import styles from "./Header.module.scss";
import { useTranslation } from "react-i18next";
import {
  NavigationButtonDownload,
  NavigationButtonNext,
  NavigationButtonPrevious,
} from "./Navigation/NavigationButtons";
import { useHistory } from "react-router-dom";
import LanguageDropdown from "./Dropdowns/LanguageDropdown";
import i18next from "i18next";
import { usePdf } from "../hooks/usePdfApi";

interface MobileHeaderProps {
  currentStep: number;
  isSuccessPage: boolean;
  isViewPage: boolean;
}

const MobileHeader: React.FC<MobileHeaderProps> = ({
  currentStep,
  isSuccessPage,
  isViewPage,
}) => {
  const { t } = useTranslation("common");
  const history = useHistory();
  const { downloadPdf } = usePdf();
  const isLastStep = currentStep === 4;
  return (
    <CHeader data-testid="header" withSubheader={true}>
      <CContainer className={styles.headerContainer}>
        <>
          <CHeaderBrand className="mr-auto">
            <CLink
              href="https://www.sartorius.com/en/products/weighing/laboratory-balances/cubis-ii"
              target="_blank"
            >
              <CImg
                data-testid="logo"
                src={sartoriusLogo}
                alt="Sartorius"
                width={150}
                height={35}
              />
            </CLink>
          </CHeaderBrand>
          <CNav className={styles.navContainer}>
            {!isViewPage && <LanguageDropdown />}
          </CNav>
        </>
      </CContainer>

      {!isViewPage && (
        <CSubheader className="border-0 text-black" data-testid="subheader">
          <CContainer>
            <CRow>
              <CCol lg="12">
                <h3 className={`mt-3 ${styles.headerText}`}>
                  {t("step1.subheader")}
                </h3>
              </CCol>
            </CRow>
          </CContainer>
          {!isSuccessPage && (
            <>
              <CContainer className={styles.subheaderContainer}>
                <CRow>
                  <CCol className={styles.stepContainer} lg="12">
                    <div className={styles.circleImg}>
                      <svg height="100" width="100">
                        <circle
                          cx="50"
                          cy="50"
                          r="40"
                          stroke="#EDEDED"
                          strokeWidth={3}
                          fill="transparent"
                        />
                        {isLastStep ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="25"
                            height="25"
                            x="37"
                            y="37"
                            viewBox="0 0 14 14"
                          >
                            <defs>
                              <clipPath id="b">
                                <rect width="14" height="14" />
                              </clipPath>
                            </defs>
                            <g id="a" clipPath="url(#b)">
                              <g transform="translate(0 1)">
                                <path
                                  d="M22.158,12,13.708,22.216,9.725,18.667,9,19.511l4.828,4.3L23,12.722Z"
                                  transform="translate(-9 -12)"
                                  fillRule="evenodd"
                                />
                              </g>
                            </g>
                          </svg>
                        ) : (
                          <svg height="100" width="100">
                            <circle
                              cx="50"
                              cy="50"
                              r="40"
                              stroke="#EDEDED"
                              strokeWidth={3}
                              fill="transparent"
                            />
                            <text
                              x="50"
                              y="50"
                              fontSize={isLastStep ? "3rem" : "2rem"}
                              textAnchor="middle"
                              dominantBaseline="middle"
                              fill="black"
                            >
                              {`${currentStep} of 3`}
                            </text>

                            <circle
                              cx="50"
                              cy="50"
                              r="40"
                              stroke="black"
                              strokeWidth={3}
                              fill="transparent"
                              strokeDasharray={`${currentStep * 84} 282`}
                              transform="rotate(-90, 50, 50)"
                            />
                          </svg>
                        )}
                        <circle
                          cx="50"
                          cy="50"
                          r="40"
                          stroke="black"
                          strokeWidth={3}
                          fill="transparent"
                          strokeDasharray={`${currentStep * 63} 282`}
                          transform="rotate(-90, 50, 50)"
                        />
                      </svg>
                    </div>
                    <div className={styles.nextButtonAndTextWrapper}>
                      <p className={styles.topOfButtonText}>
                        {`${t(`navigationSteps.${currentStep - 1}.line1`)}`}
                      </p>
                      <div>
                        {currentStep > 1 && (
                          <NavigationButtonPrevious
                            label={t("general.back")}
                            onClick={() => {
                              history.push(`/step${currentStep - 1}`);
                            }}
                            height={15}
                            width={15}
                          />
                        )}
                        {currentStep === 4 ? (
                          <NavigationButtonDownload
                            height={15}
                            width={15}
                            label={t("general.download")}
                            onClick={async () => {
                              await downloadPdf()
                            }}
                          />
                        ) : (
                          <NavigationButtonNext
                            height={15}
                            width={15}
                            label={t("general.next")}
                            onClick={() => {
                              history.push(`/step${currentStep + 1}`);
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </CCol>
                </CRow>
                <CRow>
                  <CCol lg="12"></CCol>
                </CRow>
              </CContainer>
              <CContainer className={styles.secondaryHeaderTextContainer}>
                <CRow>
                  <CCol lg="12">
                    <span className={`${styles.secondaryHeaderText}`}>
                      {i18next.exists(`common:step${currentStep}.mobileTitle`)
                        ? t(`step${currentStep}.mobileTitle`)
                        : t(`step${currentStep}.title`)}
                    </span>
                  </CCol>
                </CRow>
              </CContainer>
            </>
          )}
        </CSubheader>
      )}
    </CHeader>
  );
};

export default MobileHeader;
