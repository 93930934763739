import * as React from "react";
import styles from "./SpecifiedInstrumentSection.module.scss";
import BlackHeaderBar from "./BlackHeaderBar";
import { useTranslation } from "react-i18next";
import { CCol, CRow } from "@coreui/react";
import { SftConfiguration } from "../../api";

interface SpecifiedInstrumentSectionProps {
  model: string;
  manufacturer: string;
  type: string;
  isoCalFunction: string;
  secondSubsection: SftConfiguration[];
}

const SpecifiedInstrumentSection: React.FC<SpecifiedInstrumentSectionProps> = ({
  model,
  manufacturer,
  type,
  isoCalFunction,
  secondSubsection,
}) => {
  const { t } = useTranslation("common");
  console.log(secondSubsection)
  return (
    <div>
      <BlackHeaderBar
        text={t("step4.specifiedInstrument.headerText")}
        urlToNavigate="/step2"
        hasButton
      />
      <CRow className={styles.firstRow}>
        <CCol lg="4">
          <div className={styles.header}>
            {t("step4.specifiedInstrument.model")}
          </div>
          <div className={styles.value}>{model}</div>
        </CCol>
        <CCol lg="4">
          <div className={styles.header}>
            {t("step4.specifiedInstrument.manufacturer")}
          </div>
          <div className={styles.value}>{manufacturer}</div>
        </CCol>
        <CCol lg="4">
          <div className={styles.header}>
            {t("step4.specifiedInstrument.type")}
          </div>
          <div className={styles.value}>{type}</div>
        </CCol>
      </CRow>
      <CRow>
        <CCol lg="4">
          <div className={styles.header}>
            {t("step4.specifiedInstrument.isoCalFunction")}
          </div>
          <div className={styles.value}>{isoCalFunction}</div>
        </CCol>
      </CRow>
      <hr />
      <CRow className={styles.desktopSecondSubsection}>
        <CCol lg="3">
          <div className={styles.header}>
            {t("step4.specifiedInstrument.weighingRange")}
          </div>
          <div className={styles.header}>
            {t("step4.specifiedInstrument.maxg")}
          </div>
          <div className={styles.header}>
            {t("step4.specifiedInstrument.scaleInterval")}
          </div>
          <div className={styles.header}>
            {t("step4.specifiedInstrument.verificationScaleInterval")}
          </div>
        </CCol>
        {secondSubsection.map((x, i) => {
          return (
            <CCol lg="3">
              <div className={styles.value}>
                {secondSubsection[i].weighingRange}
              </div>
              <div className={styles.value}>{secondSubsection[i].maxWeight}</div>
              <div className={styles.value}>
                {secondSubsection[i].scaleInterval}
              </div>
              <div className={styles.value}>
                {secondSubsection[i].verificationScaleInterval}
              </div>
            </CCol>
          );
        })}
      </CRow>

      {secondSubsection.map((x, i) => {
        return (
          <div className={styles.mobileSecondSubsection}>
            <CRow>
              <CCol xs="12">
                <div className={styles.header}>
                  {t("step4.specifiedInstrument.weighingRange")}
                </div>
              </CCol>
              <CCol xs="12">
                <div className={styles.value}>
                  {secondSubsection[i].weighingRange}
                </div>
              </CCol>
              <CCol xs="12">
                <div className={styles.header}>
                  {t("step4.specifiedInstrument.maxg")}
                </div>
              </CCol>
              <CCol xs="12">
                <div className={styles.value}>{secondSubsection[i].maxWeight}</div>
              </CCol>
              <CCol xs="12">
                <div className={styles.header}>
                  {t("step4.specifiedInstrument.scaleInterval")}
                </div>
              </CCol>
              <CCol xs="12">
                <div className={styles.value}>
                  {secondSubsection[i].scaleInterval}
                </div>
              </CCol>
              <CCol xs="12">
                <div className={styles.header}>
                  {t("step4.specifiedInstrument.verificationScaleInterval")}
                </div>
              </CCol>
              <CCol xs="12">
                <div className={styles.value}>
                  {secondSubsection[i].verificationScaleInterval}
                </div>
              </CCol>
            </CRow>
            {i + 1 !== secondSubsection.length && <hr />}
          </div>
        );
      })}
    </div>
  );
};

export default SpecifiedInstrumentSection;
