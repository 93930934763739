export type NavigationStepStatus = 'not_set' | 'incomplete' | 'complete';

export enum NavigationStepsEnum {
  step1 = 'step1',
  step2 = 'step2',
  step3 = 'step3',
  step4 = 'step4',
}

export type NavigationStepStatusState = {
  [key in NavigationStepsEnum]: NavigationStepStatus;
};

export const NavigationSlugs: { [index: number]: string } = {
  1: 'Risk Assessment',
  2: 'Requirements',
  3: 'Customer Data',
  4: 'Recommendation',
};
