/* tslint:disable */
/* eslint-disable */
/**
 * Calculate assessment API.
 * API for requesting Assessment via Service Finder Tool
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OptionalServicesDTO } from './OptionalServicesDTO';
import {
    OptionalServicesDTOFromJSON,
    OptionalServicesDTOFromJSONTyped,
    OptionalServicesDTOToJSON,
} from './OptionalServicesDTO';
import type { BasicMaintanancePackageDTO } from './BasicMaintanancePackageDTO';
import {
    BasicMaintanancePackageDTOFromJSON,
    BasicMaintanancePackageDTOFromJSONTyped,
    BasicMaintanancePackageDTOToJSON,
} from './BasicMaintanancePackageDTO';
import type { StandardMaintanancePackageDTO } from './StandardMaintanancePackageDTO';
import {
    StandardMaintanancePackageDTOFromJSON,
    StandardMaintanancePackageDTOFromJSONTyped,
    StandardMaintanancePackageDTOToJSON,
} from './StandardMaintanancePackageDTO';

/**
 * 
 * @export
 * @interface PageFourDTO
 */
export interface PageFourDTO {
    /**
     * 
     * @type {string}
     * @memberof PageFourDTO
     */
    serviceFrequency: string;
    /**
     * 
     * @type {StandardMaintanancePackageDTO}
     * @memberof PageFourDTO
     */
    standardMaintanancePackageDTO: StandardMaintanancePackageDTO;
    /**
     * 
     * @type {BasicMaintanancePackageDTO}
     * @memberof PageFourDTO
     */
    basicMaintanancePackageDTO?: BasicMaintanancePackageDTO;
    /**
     * 
     * @type {OptionalServicesDTO}
     * @memberof PageFourDTO
     */
    optionalServicesDTO?: OptionalServicesDTO;
}

/**
 * Check if a given object implements the PageFourDTO interface.
 */
export function instanceOfPageFourDTO(value: object): value is PageFourDTO {
    if (!('serviceFrequency' in value) || value['serviceFrequency'] === undefined) return false;
    if (!('standardMaintanancePackageDTO' in value) || value['standardMaintanancePackageDTO'] === undefined) return false;
    return true;
}

export function PageFourDTOFromJSON(json: any): PageFourDTO {
    return PageFourDTOFromJSONTyped(json, false);
}

export function PageFourDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): PageFourDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'serviceFrequency': json['serviceFrequency'],
        'standardMaintanancePackageDTO': StandardMaintanancePackageDTOFromJSON(json['standardMaintanancePackageDTO']),
        'basicMaintanancePackageDTO': json['basicMaintanancePackageDTO'] == null ? undefined : BasicMaintanancePackageDTOFromJSON(json['basicMaintanancePackageDTO']),
        'optionalServicesDTO': json['optionalServicesDTO'] == null ? undefined : OptionalServicesDTOFromJSON(json['optionalServicesDTO']),
    };
}

export function PageFourDTOToJSON(value?: PageFourDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'serviceFrequency': value['serviceFrequency'],
        'standardMaintanancePackageDTO': StandardMaintanancePackageDTOToJSON(value['standardMaintanancePackageDTO']),
        'basicMaintanancePackageDTO': BasicMaintanancePackageDTOToJSON(value['basicMaintanancePackageDTO']),
        'optionalServicesDTO': OptionalServicesDTOToJSON(value['optionalServicesDTO']),
    };
}

