/* tslint:disable */
/* eslint-disable */
/**
 * Calculate assessment API.
 * API for requesting Assessment via Service Finder Tool
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OptionalServicesDTO } from './OptionalServicesDTO';
import {
    OptionalServicesDTOFromJSON,
    OptionalServicesDTOFromJSONTyped,
    OptionalServicesDTOToJSON,
} from './OptionalServicesDTO';
import type { InstallationPackageDTO } from './InstallationPackageDTO';
import {
    InstallationPackageDTOFromJSON,
    InstallationPackageDTOFromJSONTyped,
    InstallationPackageDTOToJSON,
} from './InstallationPackageDTO';
import type { EquipmentPackageDTO } from './EquipmentPackageDTO';
import {
    EquipmentPackageDTOFromJSON,
    EquipmentPackageDTOFromJSONTyped,
    EquipmentPackageDTOToJSON,
} from './EquipmentPackageDTO';

/**
 * 
 * @export
 * @interface PageThreeDTO
 */
export interface PageThreeDTO {
    /**
     * 
     * @type {EquipmentPackageDTO}
     * @memberof PageThreeDTO
     */
    equipmentPackageDTO: EquipmentPackageDTO;
    /**
     * 
     * @type {InstallationPackageDTO}
     * @memberof PageThreeDTO
     */
    installationPackageDTO?: InstallationPackageDTO;
    /**
     * 
     * @type {OptionalServicesDTO}
     * @memberof PageThreeDTO
     */
    optionalServicesDTO: OptionalServicesDTO;
}

/**
 * Check if a given object implements the PageThreeDTO interface.
 */
export function instanceOfPageThreeDTO(value: object): value is PageThreeDTO {
    if (!('equipmentPackageDTO' in value) || value['equipmentPackageDTO'] === undefined) return false;
    if (!('optionalServicesDTO' in value) || value['optionalServicesDTO'] === undefined) return false;
    return true;
}

export function PageThreeDTOFromJSON(json: any): PageThreeDTO {
    return PageThreeDTOFromJSONTyped(json, false);
}

export function PageThreeDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): PageThreeDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'equipmentPackageDTO': EquipmentPackageDTOFromJSON(json['equipmentPackageDTO']),
        'installationPackageDTO': json['installationPackageDTO'] == null ? undefined : InstallationPackageDTOFromJSON(json['installationPackageDTO']),
        'optionalServicesDTO': OptionalServicesDTOFromJSON(json['optionalServicesDTO']),
    };
}

export function PageThreeDTOToJSON(value?: PageThreeDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'equipmentPackageDTO': EquipmentPackageDTOToJSON(value['equipmentPackageDTO']),
        'installationPackageDTO': InstallationPackageDTOToJSON(value['installationPackageDTO']),
        'optionalServicesDTO': OptionalServicesDTOToJSON(value['optionalServicesDTO']),
    };
}

