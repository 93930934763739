import Model from "./model.svg";
import Weight from "./weight.svg";
import Equipment from "./equipment.svg";
import UsPharma from "./usPharma.svg";
import EuropePharma from "./europePharma.svg";
import PipetteTesting from "./pipetteTesting.svg";
import Balance from "./balance.svg";

const Step2Svgs: string[] = [
  Model,
  Weight,
  Equipment,
  UsPharma,
  EuropePharma,
  PipetteTesting,
  Balance,
];

export default Step2Svgs;
