/* tslint:disable */
/* eslint-disable */
/**
 * Calculate assessment API.
 * API for requesting Assessment via Service Finder Tool
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OnePointCalibrationDTO
 */
export interface OnePointCalibrationDTO {
    /**
     * 
     * @type {string}
     * @memberof OnePointCalibrationDTO
     */
    recommendedFrequency: string;
    /**
     * 
     * @type {string}
     * @memberof OnePointCalibrationDTO
     */
    recommendedTestWeight?: string;
    /**
     * 
     * @type {string}
     * @memberof OnePointCalibrationDTO
     */
    accuracyClassTestWeight?: string;
    /**
     * 
     * @type {string}
     * @memberof OnePointCalibrationDTO
     */
    testNominalTestWeight?: string;
    /**
     * 
     * @type {string}
     * @memberof OnePointCalibrationDTO
     */
    calibrationCycle?: string;
    /**
     * 
     * @type {string}
     * @memberof OnePointCalibrationDTO
     */
    actionToleranceLimit?: string;
    /**
     * 
     * @type {string}
     * @memberof OnePointCalibrationDTO
     */
    warningToleranceLimit?: string;
}

/**
 * Check if a given object implements the OnePointCalibrationDTO interface.
 */
export function instanceOfOnePointCalibrationDTO(value: object): value is OnePointCalibrationDTO {
    if (!('recommendedFrequency' in value) || value['recommendedFrequency'] === undefined) return false;
    return true;
}

export function OnePointCalibrationDTOFromJSON(json: any): OnePointCalibrationDTO {
    return OnePointCalibrationDTOFromJSONTyped(json, false);
}

export function OnePointCalibrationDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): OnePointCalibrationDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'recommendedFrequency': json['recommendedFrequency'],
        'recommendedTestWeight': json['recommendedTestWeight'] == null ? undefined : json['recommendedTestWeight'],
        'accuracyClassTestWeight': json['accuracyClassTestWeight'] == null ? undefined : json['accuracyClassTestWeight'],
        'testNominalTestWeight': json['testNominalTestWeight'] == null ? undefined : json['testNominalTestWeight'],
        'calibrationCycle': json['calibrationCycle'] == null ? undefined : json['calibrationCycle'],
        'actionToleranceLimit': json['actionToleranceLimit'] == null ? undefined : json['actionToleranceLimit'],
        'warningToleranceLimit': json['warningToleranceLimit'] == null ? undefined : json['warningToleranceLimit'],
    };
}

export function OnePointCalibrationDTOToJSON(value?: OnePointCalibrationDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'recommendedFrequency': value['recommendedFrequency'],
        'recommendedTestWeight': value['recommendedTestWeight'],
        'accuracyClassTestWeight': value['accuracyClassTestWeight'],
        'testNominalTestWeight': value['testNominalTestWeight'],
        'calibrationCycle': value['calibrationCycle'],
        'actionToleranceLimit': value['actionToleranceLimit'],
        'warningToleranceLimit': value['warningToleranceLimit'],
    };
}

