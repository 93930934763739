import { useEffect, useState, useRef, ChangeEvent } from "react";
import styles from "./step2.module.scss";
import HelpTextButton from "../components/HelpTextButton";
import { useTranslation } from "react-i18next";
import { getTranslationArray } from "../utils/LanguageUtils";
import { useRecoilState } from "recoil";
import { configurationState, currentStepState } from "../states";
import { NavigationWarning } from "../components/Navigation/NavigationWarning";
import Step2SectionBar from "../components/DownloadRow/Step2SectionBar";
import { CFormGroup, CInput, CSelect } from "@coreui/react";
import { getSectionsData } from "../utils/Step2SectionsUtils";
import { Step2Selections } from "../types/Configuration";
import Step2Svgs from "../assets/icons/Step2Svgs";
import { useHistory } from "react-router-dom";
import SearchableDropdown from "../components/Dropdowns/SearchableDropdown";
import models from "../data/models";
import { trackPageImpression } from "../services/googleTagManager";

const Step2: React.FC = () => {
  const dropdownOptions = { model: models };
  const history = useHistory();
  const [, setCurrentStep] = useRecoilState(currentStepState);
  const [configuration, setConfiguration] = useRecoilState(configurationState);
  const [step2Data, setStep2Data] = useState<Step2Selections>(
    configuration.step2Data
  );

  const { t } = useTranslation("common");

  const sections = getSectionsData(t);
  const [missingFields, setMissingFields] = useState([]);

  useEffect(() => {
    setConfiguration({
      ...configuration,
      step2Data: {
        ...step2Data,
        isCompleted:
          step2Data.model !== undefined &&
          step2Data.balance !== undefined &&
          step2Data.equipment !== undefined &&
          step2Data.europePharma !== undefined &&
          step2Data.pipetteTesting !== undefined &&
          step2Data.usPharma !== undefined &&
          step2Data.weight !== undefined &&
          step2Data.weightType !== undefined,
      },
    });

    // eslint-disable-next-line
  }, [
    step2Data.model,
    step2Data.balance,
    step2Data.equipment,
    step2Data.europePharma,
    step2Data.pipetteTesting,
    step2Data.usPharma,
    step2Data.weight,
    step2Data.weightType,
  ]);

  const trackMissingFields = () => {
    let tempMissingFields: string[] = [];
    sections.forEach((section) => {
      if (
        section.textboxName?.length > 0 &&
        (configuration.step2Data[section.textboxName] === undefined ||
          configuration.step2Data[section.textboxName].length === 0)
      ) {
        tempMissingFields.push(section.leftTopText);
      } else if (configuration.step2Data[section.dropdownName] === undefined) {
        tempMissingFields.push(section.leftTopText);
      }
    });

    setMissingFields(tempMissingFields);
  };

  useEffect(
    () => {
      if (!configuration.step1Data.isCompleted) {
        history.push("/step1");
        return;
      }

      setCurrentStep(2);
      trackMissingFields();
      trackPageImpression(2);

    },
    // eslint-disable-next-line
    []
  );

  useEffect(() => {
    trackMissingFields();
    // eslint-disable-next-line
  }, [configuration]);

  const helpTextArray = getTranslationArray(t, "step2.help");

  return (
    <>
      <NavigationWarning missingFields={missingFields} />
      <HelpTextButton
        title={t(`step2.help.title`)}
        header={t("general.needHelp")}
        content={
          <div>
            {helpTextArray.map((x, i) => (
              <p key={`help-${i}`}>{x}</p>
            ))}
          </div>
        }
      />
      {sections.map((x, i) => {
        return (
          <Step2SectionBar
            svgSrc={Step2Svgs[i]}
            key={`s2sb-${i}`}
            leftSideTopText={x.leftTopText}
            leftSideBottomText={x.leftBottomText}
            rightSideComponent={
              <CFormGroup className={styles.dropdown}>
                <div className={styles.rightSideWrapper}>
                  {x.textboxName && (
                    <>
                      <CInput
                        data-testid="number-input"
                        type="number"
                        id="weight"
                        name="weight"
                        step="any"
                        className={styles.textbox}
                        value={step2Data[x.textboxName]}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                          let value = e.target.value.replace(',', '.');  // Replace commas with dots
                          const hasValue = value.length > 0 && !isNaN(Number(value));
                          setStep2Data({
                            ...step2Data,
                            [x.textboxName]: hasValue ? value : undefined,
                          });
                        }}
                        placeholder={x.textboxPlaceholder}
                      />
                    </>
                  )}
                  {x.isSearchable ? (
                    <SearchableDropdown
                      dropdownOptions={
                        new Map(
                          dropdownOptions[x.dropdownName].map(
                            (value: string) => [value, value]
                          )
                        )
                      }
                      searchPlaceholder={x.searchPlaceholder}
                      dropdownPlaceholder={x.dropdownPlaceholder}
                      selectedValue={
                        step2Data[x.dropdownName]
                          ? step2Data[x.dropdownName]
                          : x.defaultDropdownValue ?? ""
                      }
                      setValue={(value: string) => {
                        setStep2Data({
                          ...step2Data,
                          [x.dropdownName]: value,
                        });
                      }}
                    />
                  ) : (
                    <CSelect
                      id={`ddwn-${i}`}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setStep2Data({
                          ...step2Data,
                          [x.dropdownName]: e.target.value,
                        });
                      }}
                      className={`${styles.singleSelect} ${
                        x.textboxPlaceholder ? styles.withTextbox : ""
                      } ${
                        !step2Data[x.dropdownName] && !x.defaultDropdownValue
                          ? styles.disabledOption
                          : ""
                      }`}
                      defaultValue={
                        step2Data[x.dropdownName]
                          ? step2Data[x.dropdownName]
                          : x.defaultDropdownValue ?? ""
                      }
                    >
                      {x.defaultDropdownValue === undefined && (
                        <option value="" disabled>
                          {x.dropdownPlaceholder}
                        </option>
                      )}
                      {Array.from(x.dropdownOptions.keys()).map((k) => {
                        return (
                          <option value={x.dropdownOptions.get(k)} key={k}>
                            {x.dropdownOptions.get(k)}
                          </option>
                        );
                      })}
                    </CSelect>
                  )}
                </div>
              </CFormGroup>
            }
          />
        );
      })}
    </>
  );
};

export default Step2;
