export enum Matcher {
  Zero=0,
  One=1,
  Two=2,
  Three=3,
  Five=5,
  Six=6,
  Ten=10
}

export enum Impact {
  Low=1,
  Medium=3,
  High=5,
}

export enum Probability {
  Low=1,
  Medium=3,
  High=5,
}

export enum ImpactWithNotApplicable {
  Low=2,
  Medium=6,
  High=10,
  NotApplicable=0,
}

export enum ImpactMatch {
  Zero="Not Applicable",
  One="Low",
  Two="Low",
  Three="Medium",
  Five="High",
  Six="Medium",
  Ten="High"
}

export interface Step1Selections {
  firstMeasurement?: ImpactWithNotApplicable;
  secondMeasurement?: Impact;
  thirdMeasurement?: Probability;
  fourthMeasurement?: Probability;
}

interface Step1Configuration extends Step1Selections {
  isCompleted: boolean;
}

export interface Step2Selections {
  model?: string;
  weight?: number;
  weightType?: string;
  equipment?: string;
  usPharma?: string;
  europePharma?: string;
  pipetteTesting?: string;
  balance?: string;
}

interface Step2Configuration extends Step2Selections {
  isCompleted: boolean;
}

export interface Step3Selections {
  company?: string;
  country?: string;
  street?: string;
  addressNumber?: string;
  zipCode?: string;
  city?: string;
  firstName?: string;
  lastName?: string;
  emailAddress?: string;
  department?: string;
  isCompleted: boolean;
}

export interface Configuration {
  step1Data?: Step1Configuration;
  step2Data?: Step2Configuration;
  step3Data?: Step3Selections;
}
