/* tslint:disable */
/* eslint-disable */
/**
 * Calculate assessment API.
 * API for requesting Assessment via Service Finder Tool
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PageTwoDTO
 */
export interface PageTwoDTO {
    /**
     * 
     * @type {string}
     * @memberof PageTwoDTO
     */
    minWeight: string;
    /**
     * 
     * @type {string}
     * @memberof PageTwoDTO
     */
    equipmentQualificationNeeded: string;
    /**
     * 
     * @type {string}
     * @memberof PageTwoDTO
     */
    requiresComplianceUSA: string;
    /**
     * 
     * @type {string}
     * @memberof PageTwoDTO
     */
    requiresComplianceEU: string;
    /**
     * 
     * @type {string}
     * @memberof PageTwoDTO
     */
    isForTestPipettes: string;
    /**
     * 
     * @type {string}
     * @memberof PageTwoDTO
     */
    fullMaintenanceReqPos: string;
    /**
     * 
     * @type {string}
     * @memberof PageTwoDTO
     */
    requiredProcessAccuracy: string;
    /**
     * 
     * @type {string}
     * @memberof PageTwoDTO
     */
    safetyFactor: string;
}

/**
 * Check if a given object implements the PageTwoDTO interface.
 */
export function instanceOfPageTwoDTO(value: object): value is PageTwoDTO {
    if (!('minWeight' in value) || value['minWeight'] === undefined) return false;
    if (!('equipmentQualificationNeeded' in value) || value['equipmentQualificationNeeded'] === undefined) return false;
    if (!('requiresComplianceUSA' in value) || value['requiresComplianceUSA'] === undefined) return false;
    if (!('requiresComplianceEU' in value) || value['requiresComplianceEU'] === undefined) return false;
    if (!('isForTestPipettes' in value) || value['isForTestPipettes'] === undefined) return false;
    if (!('fullMaintenanceReqPos' in value) || value['fullMaintenanceReqPos'] === undefined) return false;
    if (!('requiredProcessAccuracy' in value) || value['requiredProcessAccuracy'] === undefined) return false;
    if (!('safetyFactor' in value) || value['safetyFactor'] === undefined) return false;
    return true;
}

export function PageTwoDTOFromJSON(json: any): PageTwoDTO {
    return PageTwoDTOFromJSONTyped(json, false);
}

export function PageTwoDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): PageTwoDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'minWeight': json['minWeight'],
        'equipmentQualificationNeeded': json['equipmentQualificationNeeded'],
        'requiresComplianceUSA': json['requiresComplianceUSA'],
        'requiresComplianceEU': json['requiresComplianceEU'],
        'isForTestPipettes': json['isForTestPipettes'],
        'fullMaintenanceReqPos': json['fullMaintenanceReqPos'],
        'requiredProcessAccuracy': json['requiredProcessAccuracy'],
        'safetyFactor': json['safetyFactor'],
    };
}

export function PageTwoDTOToJSON(value?: PageTwoDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'minWeight': value['minWeight'],
        'equipmentQualificationNeeded': value['equipmentQualificationNeeded'],
        'requiresComplianceUSA': value['requiresComplianceUSA'],
        'requiresComplianceEU': value['requiresComplianceEU'],
        'isForTestPipettes': value['isForTestPipettes'],
        'fullMaintenanceReqPos': value['fullMaintenanceReqPos'],
        'requiredProcessAccuracy': value['requiredProcessAccuracy'],
        'safetyFactor': value['safetyFactor'],
    };
}

