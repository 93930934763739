/* tslint:disable */
/* eslint-disable */
/**
 * Calculate assessment API.
 * API for requesting Assessment via Service Finder Tool
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OptionalServicesDTO
 */
export interface OptionalServicesDTO {
    /**
     * 
     * @type {string}
     * @memberof OptionalServicesDTO
     */
    balanceConformityCertificate: string;
    /**
     * 
     * @type {string}
     * @memberof OptionalServicesDTO
     */
    balanceTestProtocol?: string;
    /**
     * 
     * @type {string}
     * @memberof OptionalServicesDTO
     */
    repeatabilityMeasurements?: string;
    /**
     * 
     * @type {string}
     * @memberof OptionalServicesDTO
     */
    checkOfConformity?: string;
}

/**
 * Check if a given object implements the OptionalServicesDTO interface.
 */
export function instanceOfOptionalServicesDTO(value: object): value is OptionalServicesDTO {
    if (!('balanceConformityCertificate' in value) || value['balanceConformityCertificate'] === undefined) return false;
    return true;
}

export function OptionalServicesDTOFromJSON(json: any): OptionalServicesDTO {
    return OptionalServicesDTOFromJSONTyped(json, false);
}

export function OptionalServicesDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionalServicesDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'balanceConformityCertificate': json['balanceConformityCertificate'],
        'balanceTestProtocol': json['balanceTestProtocol'] == null ? undefined : json['balanceTestProtocol'],
        'repeatabilityMeasurements': json['repeatabilityMeasurements'] == null ? undefined : json['repeatabilityMeasurements'],
        'checkOfConformity': json['checkOfConformity'] == null ? undefined : json['checkOfConformity'],
    };
}

export function OptionalServicesDTOToJSON(value?: OptionalServicesDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'balanceConformityCertificate': value['balanceConformityCertificate'],
        'balanceTestProtocol': value['balanceTestProtocol'],
        'repeatabilityMeasurements': value['repeatabilityMeasurements'],
        'checkOfConformity': value['checkOfConformity'],
    };
}

