import * as React from "react";
import styles from "./CustomerDataSection.module.scss";
import BlackHeaderBar from "./BlackHeaderBar";
import { useTranslation } from "react-i18next";
import { CCol, CRow } from "@coreui/react";

interface CustomerDataSectionProps {
  company: string;
  streetAndAddressNumber: string;
  zipCodeAndCity: string;
  country: string;
  department?: string;
  customerContact: string;
}

const CustomerDataSection: React.FC<CustomerDataSectionProps> = ({
  company,
  country,
  customerContact,
  streetAndAddressNumber,
  zipCodeAndCity,
  department,
}) => {
  const { t } = useTranslation("common");

  return (
    <div>
      <BlackHeaderBar
        text={t("step4.customerData.headerText")}
        urlToNavigate="/step3"
        hasButton
      />
      <CRow className={styles.firstRow}>
        <CCol xs="12" lg="4">
          <div className={styles.header}>{t("step4.customerData.company")}</div>
          <div className={styles.value}>{company}</div>
        </CCol>
        <CCol xs="12" lg="4">
          <div className={styles.header}>
            {t("step4.customerData.streetAndAddressNumber")}
          </div>
          <div className={styles.value}>{streetAndAddressNumber}</div>
        </CCol>
        <CCol xs="12" lg="4">
          <div className={styles.header}>
            {t("step4.customerData.zipCodeAndCity")}
          </div>
          <div className={styles.value}>{zipCodeAndCity}</div>
        </CCol>
      </CRow>
      <CRow className={styles.secondRow}>
        <CCol xs="12" lg="4">
          <div className={styles.header}>{t("step4.customerData.country")}</div>
          <div className={styles.value}>{country}</div>
        </CCol>
        <CCol xs="12" lg="4">
          <div className={styles.header}>
            {t("step4.customerData.department")}
          </div>
          <div className={styles.value}>{department}</div>
        </CCol>
        <CCol xs="12" lg="4">
          <div className={styles.header}>
            {t("step4.customerData.customerContact")}
          </div>
          <div className={styles.value}>{customerContact}</div>
        </CCol>
      </CRow>
    </div>
  );
};

export default CustomerDataSection;
