import React from "react";
import styles from "./NavigationTitle.module.scss";
import {
  NavigationButtonDownload,
  NavigationButtonNext,
  NavigationButtonPrevious,
} from "./NavigationButtons";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { CTooltip } from "@coreui/react";
import IIcon from "../../assets/icons/i.svg";
import i18next from "i18next";
import { usePdf } from "../../hooks/usePdfApi";

interface NavigationTitleProps {
  currentStep: number;
}

const NavigationTitle: React.FC<NavigationTitleProps> = ({ currentStep }) => {
  const { t } = useTranslation("common");
  const { downloadPdf } = usePdf();
  const history = useHistory();

  return (
    <div className={styles.navigationButtons}>
      <div className={"d-flex flex-row"}>
        <div>
          <h3
            data-testid="step-header"
            className={`pt-1 ${styles.navigationTitle}`}
          >
            {t(`step${currentStep}.title`)}
            {i18next.exists(`common:step${currentStep}.tooltip`) && (
              <CTooltip
                content={
                  <div className="text-left">
                    {t(`step${currentStep}.tooltip`)}
                  </div>
                }
              >
                <img alt="expand" src={IIcon} width={20} height={20} />
              </CTooltip>
            )}
          </h3>
        </div>
        <div className="pl-8 ml-auto text-nowrap">
          {currentStep > 1 && (
            <NavigationButtonPrevious
              height={25}
              width={25}
              data-testid="prev-button"
              onClick={() => {
                history.push(`/step${currentStep - 1}`);
              }}
              label={t("general.back")}
            />
          )}
          {currentStep === 4 ? (
            <NavigationButtonDownload
              height={25}
              width={25}
              label={t("general.download")}
              onClick={async () => {
                await downloadPdf()
              }}
            />
          ) : (
            <NavigationButtonNext
              height={25}
              width={25}
              label={t("general.next")}
              onClick={() => {
                history.push(`/step${currentStep + 1}`);
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default NavigationTitle;
