import * as React from "react";
import styles from "./Step2SectionBar.module.scss";

interface AccordionElementProps {
  leftSideTopText: string;
  leftSideBottomText: string;
  rightSideComponent: any;
  svgSrc: string;
}

const Step2SectionBar: React.FC<AccordionElementProps> = (
  props: AccordionElementProps
) => {
  const { leftSideBottomText, leftSideTopText, rightSideComponent, svgSrc } =
    props;

  return (
    <div className={`${styles.accordion} accordion-element`}>
      <div tabIndex={0} className={`${styles.row} row flex-nowrap`}>
        <div className={styles.leftSideWrapper}>
          <div>
            <img className={styles.leftSvg} src={svgSrc} />
          </div>
          <div>
            <p className={styles.topText}>{leftSideTopText}</p>
            <p className={styles.bottomText}>{leftSideBottomText}</p>
          </div>
        </div>
        <div className={styles.rightSideWrapper}>{rightSideComponent}</div>
      </div>
    </div>
  );
};

export default Step2SectionBar;
