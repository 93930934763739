import React, { useEffect, useMemo, useRef, useState } from "react";
import styles from "./step1.module.scss";
import HelpTextButton from "../components/HelpTextButton";
import { useTranslation } from "react-i18next";
import { getTranslationArray } from "../utils/LanguageUtils";
import { useRecoilState } from "recoil";
import { configurationState, currentStepState } from "../states";
import ProbabilityScore from "../assets/icons/probabilityScore.svg";
import ImpactScore from "../assets/icons/impactScore.svg";
import { NavigationWarning } from "../components/Navigation/NavigationWarning";
import {
  CCol,
  CFormGroup,
  CInputRadio,
  CLabel,
  CRow,
  CTooltip,
} from "@coreui/react";
import IIcon from "../assets/icons/i.svg";
import {
  Impact,
  ImpactWithNotApplicable,
  Probability,
  Step1Selections,
} from "../types/Configuration";
import { trackPageImpression } from "../services/googleTagManager";

const Step1: React.FC = React.memo(() => {
  const [, setCurrentStep] = useRecoilState(currentStepState);
  const [configuration, setConfiguration] = useRecoilState(configurationState);
  const [step1Data, setStep1Data] = useState<Step1Selections>(
    configuration.step1Data
  );
  const { t } = useTranslation("common");

  const [missingFields, setMissingFields] = useState([]);
  const tracked = useRef<boolean>(false);

  useEffect(() => {
    if (step1Data) {
      setConfiguration({
        ...configuration,
        step1Data: {
          ...step1Data,
          isCompleted:
            step1Data.firstMeasurement !== undefined &&
            step1Data.secondMeasurement !== undefined &&
            step1Data.thirdMeasurement !== undefined &&
            step1Data.fourthMeasurement !== undefined,
        },
      });
    }
    // eslint-disable-next-line
  }, [step1Data]);

  useEffect(() => {
    trackMissingFields();
    // eslint-disable-next-line
  }, [configuration]);

  const trackMissingFields = () => {
    let tempMissingFields = [];
    if (configuration.step1Data.firstMeasurement === undefined) {
      tempMissingFields.push([t("step1.sections.0.substeps.0.header")]);
    }
    if (configuration.step1Data.secondMeasurement === undefined) {
      tempMissingFields.push(t("step1.sections.0.substeps.1.header"));
    }
    if (configuration.step1Data.thirdMeasurement === undefined) {
      tempMissingFields.push(t("step1.sections.1.substeps.0.header"));
    }
    if (configuration.step1Data.fourthMeasurement === undefined) {
      tempMissingFields.push(t("step1.sections.1.substeps.1.header"));
    }

    setMissingFields(tempMissingFields);
  };

  useEffect(
    () => {
      setCurrentStep(1);
      trackMissingFields();
    },
    // eslint-disable-next-line
    []
  );
  console.log("Component rendered");
  // useEffect(() => {
  //   if (!tracked.current) {
  //     trackPageImpression(1);
  //     console.log("BINGO")
  //     tracked.current = true;
  //   }
  // },[]);

  const helpTextArray = getTranslationArray(t, "step1.help");

  return (
    <>
      <NavigationWarning missingFields={missingFields} />
      <HelpTextButton
        title={t(`step1.help.title`)}
        header={t("general.needHelp")}
        content={
          <div>
            {helpTextArray.map((x, i) => (
              <p key={`help-${i}`}>{x}</p>
            ))}
          </div>
        }
      />
      {/* Section 1 */}
      <div className={styles.section}>
        <div>
          <div className={styles.headerWrapper}>
            <img
              src={ImpactScore}
              className={styles.icon}
              alt="ProbabilityScore"
              title="ProbabilityScore"
            />
            <h2 className={styles.title}>
              {`${t("step1.sections.0.title")} `}
              <div className={styles.tooltip}>
                <CTooltip
                  content={
                    <div className="text-left">
                      {t("step1.sections.0.tooltip")}
                    </div>
                  }
                >
                  <img alt="expand" src={IIcon} width={20} height={20} />
                </CTooltip>
              </div>
            </h2>
          </div>
        </div>
        {/* Substep 1 */}
        <CRow className={styles.headerRow}>
          <CCol lg="12">
            <h4>{t("step1.sections.0.substeps.0.header")}</h4>
          </CCol>
        </CRow>
        <CRow className={styles.secondRow}>
          <CCol lg="3">
            <CFormGroup variant="custom-radio" inline>
              <CInputRadio
                onClick={() => {
                  setStep1Data({
                    ...step1Data,
                    firstMeasurement: ImpactWithNotApplicable.Low,
                  });
                }}
                custom
                id="radio00"
                name="exampleRadios0"
                defaultChecked={
                  configuration.step1Data?.firstMeasurement ===
                  ImpactWithNotApplicable.Low
                }
                value={2}
              />
              <CLabel
                className={styles.radioLabel}
                variant="custom-checkbox"
                htmlFor="radio00"
              >
                {t("step1.sections.0.substeps.0.options.0.label")}
              </CLabel>
            </CFormGroup>
          </CCol>
          <CCol lg="3">
            <CFormGroup variant="custom-radio" inline>
              <CInputRadio
                onClick={() => {
                  setStep1Data({
                    ...step1Data,
                    firstMeasurement: ImpactWithNotApplicable.Medium,
                  });
                }}
                custom
                name="exampleRadios0"
                defaultChecked={
                  configuration.step1Data?.firstMeasurement ===
                  ImpactWithNotApplicable.Medium
                }
                id="radio01"
                value={6}
              />
              <CLabel
                className={styles.radioLabel}
                variant="custom-checkbox"
                htmlFor="radio01"
              >
                {t("step1.sections.0.substeps.0.options.1.label")}
              </CLabel>
            </CFormGroup>
          </CCol>
          <CCol lg="3">
            <CFormGroup variant="custom-radio" inline>
              <CInputRadio
                onClick={() => {
                  setStep1Data({
                    ...step1Data,
                    firstMeasurement: ImpactWithNotApplicable.High,
                  });
                }}
                custom
                name="exampleRadios0"
                defaultChecked={
                  configuration.step1Data?.firstMeasurement ===
                  ImpactWithNotApplicable.High
                }
                id="radio02"
                value={10}
              />
              <CLabel
                className={styles.radioLabel}
                variant="custom-checkbox"
                htmlFor="radio02"
              >
                {t("step1.sections.0.substeps.0.options.2.label")}
              </CLabel>
            </CFormGroup>
          </CCol>
          <CCol lg="3">
            <CFormGroup variant="custom-radio" inline>
              <CInputRadio
                onClick={() => {
                  setStep1Data({
                    ...step1Data,
                    firstMeasurement: ImpactWithNotApplicable.NotApplicable,
                  });
                }}
                custom
                name="exampleRadios0"
                defaultChecked={
                  configuration.step1Data?.firstMeasurement ===
                  ImpactWithNotApplicable.NotApplicable
                }
                id="radio03"
                value={0}
              />
              <CLabel
                className={styles.radioLabel}
                variant="custom-checkbox"
                htmlFor="radio03"
              >
                {t("step1.sections.0.substeps.0.options.3.label")}
              </CLabel>
            </CFormGroup>
          </CCol>
        </CRow>

        {/* Substep 2 */}
        <CRow className={styles.headerRow}>
          <CCol lg="12">
            <h4>{t("step1.sections.0.substeps.1.header")}</h4>
          </CCol>
        </CRow>
        <CRow>
          <CCol lg="3">
            <CFormGroup variant="custom-radio" inline>
              <CInputRadio
                onClick={() => {
                  setStep1Data({
                    ...step1Data,
                    secondMeasurement: Impact.Low,
                  });
                }}
                defaultChecked={
                  configuration.step1Data?.secondMeasurement === Impact.Low
                }
                custom
                id="radio10"
                name="exampleRadios1"
                value={1}
              />
              <CLabel
                className={styles.radioLabel}
                variant="custom-checkbox"
                htmlFor="radio10"
              >
                {t("step1.sections.0.substeps.1.options.0.label")}
              </CLabel>
            </CFormGroup>
          </CCol>
          <CCol lg="3">
            <CFormGroup variant="custom-radio" inline>
              <CInputRadio
                onClick={() => {
                  setStep1Data({
                    ...step1Data,
                    secondMeasurement: Impact.Medium,
                  });
                }}
                defaultChecked={
                  configuration.step1Data?.secondMeasurement === Impact.Medium
                }
                custom
                name="exampleRadios1"
                id="radio11"
                value={3}
              />
              <CLabel
                className={styles.radioLabel}
                variant="custom-checkbox"
                htmlFor="radio11"
              >
                {t("step1.sections.0.substeps.1.options.1.label")}
              </CLabel>
            </CFormGroup>
          </CCol>
          <CCol lg="3">
            <CFormGroup variant="custom-radio" inline>
              <CInputRadio
                onClick={() => {
                  setStep1Data({
                    ...step1Data,
                    secondMeasurement: Impact.High,
                  });
                }}
                custom
                defaultChecked={
                  configuration.step1Data?.secondMeasurement === Impact.High
                }
                name="exampleRadios1"
                id="radio12"
                value={5}
              />
              <CLabel
                className={styles.radioLabel}
                variant="custom-checkbox"
                htmlFor="radio12"
              >
                {t("step1.sections.0.substeps.1.options.2.label")}
              </CLabel>
            </CFormGroup>
          </CCol>
        </CRow>
      </div>

      {/* Section 2 */}
      <div className={styles.section}>
        <div>
          <div className={styles.headerWrapper}>
            <img
              src={ProbabilityScore}
              className={styles.icon}
              alt="ProbabilityScore"
              title="ProbabilityScore"
            />
            <h2 className={styles.title}>
              {`${t("step1.sections.1.title")} `}
              <div className={styles.tooltip}>
                <CTooltip
                  content={
                    <div className="text-left">
                      {t("step1.sections.1.tooltip")}
                    </div>
                  }
                >
                  <img alt="expand" src={IIcon} width={20} height={20} />
                </CTooltip>
              </div>
            </h2>
          </div>
        </div>
        {/* Substep 1 */}
        <CRow className={styles.headerRow}>
          <CCol lg="12">
            <h4 className={styles.headerWithTextUnder}>
              {t("step1.sections.1.substeps.0.header")}
            </h4>
            <span className={styles.underHeaderText}>
              {t("step1.sections.1.substeps.0.underHeader")}
            </span>
          </CCol>
        </CRow>
        <CRow className={styles.secondRow}>
          <CCol lg="3">
            <CFormGroup variant="custom-radio" inline>
              <CInputRadio
                onClick={() => {
                  setStep1Data({
                    ...step1Data,
                    thirdMeasurement: Probability.Low,
                  });
                }}
                custom
                defaultChecked={
                  configuration.step1Data?.thirdMeasurement === Probability.Low
                }
                id="radio20"
                name="exampleRadios2"
                value={1}
              />
              <CLabel
                className={styles.radioLabel}
                variant="custom-checkbox"
                htmlFor="radio20"
              >
                {t("step1.sections.1.substeps.0.options.0.label")}
              </CLabel>
            </CFormGroup>
          </CCol>
          <CCol lg="3">
            <CFormGroup variant="custom-radio" inline>
              <CInputRadio
                onClick={() => {
                  setStep1Data({
                    ...step1Data,
                    thirdMeasurement: Probability.Medium,
                  });
                }}
                custom
                defaultChecked={
                  configuration.step1Data?.thirdMeasurement ===
                  Probability.Medium
                }
                name="exampleRadios2"
                id="radio21"
                value={3}
              />
              <CLabel
                className={styles.radioLabel}
                variant="custom-checkbox"
                htmlFor="radio21"
              >
                {t("step1.sections.1.substeps.0.options.1.label")}
              </CLabel>
            </CFormGroup>
          </CCol>
          <CCol lg="3">
            <CFormGroup variant="custom-radio" inline>
              <CInputRadio
                onClick={() => {
                  setStep1Data({
                    ...step1Data,
                    thirdMeasurement: Probability.High,
                  });
                }}
                defaultChecked={
                  configuration.step1Data?.thirdMeasurement === Probability.High
                }
                custom
                name="exampleRadios2"
                id="radio22"
                value={5}
              />
              <CLabel
                className={styles.radioLabel}
                variant="custom-checkbox"
                htmlFor="radio22"
              >
                {t("step1.sections.1.substeps.0.options.2.label")}
              </CLabel>
            </CFormGroup>
          </CCol>
        </CRow>

        {/* Substep 2 */}
        <CRow className={styles.headerRow}>
          <CCol lg="12">
            <h4>{t("step1.sections.1.substeps.1.header")}</h4>
          </CCol>
        </CRow>
        <CRow>
          <CCol lg="3">
            <CFormGroup variant="custom-radio" inline>
              <CInputRadio
                onClick={() => {
                  setStep1Data({
                    ...step1Data,
                    fourthMeasurement: Probability.Low,
                  });
                }}
                defaultChecked={
                  configuration.step1Data?.fourthMeasurement === Probability.Low
                }
                custom
                id="radio30"
                name="exampleRadios3"
                value={1}
              />
              <CLabel
                className={styles.radioLabel}
                variant="custom-checkbox"
                htmlFor="radio30"
              >
                {t("step1.sections.1.substeps.1.options.0.label")}
              </CLabel>
            </CFormGroup>
          </CCol>
          <CCol lg="3">
            <CFormGroup variant="custom-radio" inline>
              <CInputRadio
                onClick={() => {
                  setStep1Data({
                    ...step1Data,
                    fourthMeasurement: Probability.Medium,
                  });
                }}
                custom
                defaultChecked={
                  configuration.step1Data?.fourthMeasurement ===
                  Probability.Medium
                }
                name="exampleRadios3"
                id="radio31"
                value={3}
              />
              <CLabel
                className={styles.radioLabel}
                variant="custom-checkbox"
                htmlFor="radio31"
              >
                {t("step1.sections.1.substeps.1.options.1.label")}
              </CLabel>
            </CFormGroup>
          </CCol>
          <CCol className={styles.bottomColumn} lg="3">
            <CFormGroup variant="custom-radio" inline>
              <CInputRadio
                onClick={() => {
                  setStep1Data({
                    ...step1Data,
                    fourthMeasurement: Probability.High,
                  });
                }}
                custom
                defaultChecked={
                  configuration.step1Data?.fourthMeasurement ===
                  Probability.High
                }
                name="exampleRadios3"
                id="radio32"
                value={5}
              />
              <CLabel
                className={styles.radioLabel}
                variant="custom-checkbox"
                htmlFor="radio32"
              >
                {t("step1.sections.1.substeps.1.options.2.label")}
              </CLabel>
            </CFormGroup>
          </CCol>
        </CRow>
      </div>
    </>
  );
});

export default Step1;
