import env from "@beam-australia/react-env";
import { AssessmentApi, Configuration } from "../api";
import { useRecoilState } from "recoil";
import { configurationState } from "../states";

export const useAssessment = () => {
  const [configuration] = useRecoilState(configurationState);
  const getAssessment = async () => {
    const api = new AssessmentApi(
      new Configuration({
        basePath: env("BACKEND_URL"),
        // headers: { "Ocp-Apim-Subscription-Key": env("APIM_SUBSCRIPTION") },
      })
    );

    try {
      return await api.requestAssessment({
        assessmentDTO: {
          addressNumber: configuration.step3Data.addressNumber,
          city: configuration.step3Data.city,
          company: configuration.step3Data.company,
          country: configuration.step3Data.country,
          department: configuration.step3Data.department,
          emailAddress: configuration.step3Data.emailAddress,
          firstName: configuration.step3Data.firstName,
          lastName: configuration.step3Data.lastName,
          street: configuration.step3Data.street,
          zipCode: configuration.step3Data.zipCode,
          equipmentQualification: configuration.step2Data.equipment,
          europePharmaCompliance: configuration.step2Data.europePharma,
          usPharmaCompliance: configuration.step2Data.usPharma,
          model: configuration.step2Data.model,
          pipetteTesting: configuration.step2Data.pipetteTesting,
          smallestNetWeight: configuration.step2Data.weight,
          balanceUnits: configuration.step2Data.weightType,
          fullBalanceMaintenanceRequired: configuration.step2Data.balance,
          impactIncorrectMeasurementsSafety:
            configuration.step1Data.firstMeasurement,
          impactIncorrectMeasurementBusiness:
            configuration.step1Data.secondMeasurement,
          probabilityIncorrectMeasurement:
            configuration.step1Data.thirdMeasurement,
          probabilityIncorrectMeasurementUndetected:
            configuration.step1Data.fourthMeasurement,
        },
      });
    } catch (e) {
      console.log("error is:");
      console.log(e);
    }
  };

  return {
    getAssessment,
  };
};
