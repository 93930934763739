import { CModalFooter, CModal, CModalHeader, CModalBody } from "@coreui/react";

interface ConfigurationWarningProps {
  show: boolean;
  title: string;
  onClose: () => void;
  children?: React.ReactNode;
}

const ConfigurationWarning: React.FC<ConfigurationWarningProps> = (
  props
): JSX.Element => {
  const { show, title, onClose, children } = props;

  return (
    <CModal
      show={show}
      onClose={onClose}
      centered={true}
      className="configuration-warning"
    >
      <CModalHeader closeButton>
        <span className="justify-content-start">
          <i className="icon-warning lead" /> {title}
        </span>
      </CModalHeader>
      <CModalBody>{children}</CModalBody>
    </CModal>
  );
};

export default ConfigurationWarning;
