/* tslint:disable */
/* eslint-disable */
/**
 * Calculate assessment API.
 * API for requesting Assessment via Service Finder Tool
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SftConfiguration
 */
export interface SftConfiguration {
    /**
     * 
     * @type {string}
     * @memberof SftConfiguration
     */
    model: string;
    /**
     * 
     * @type {string}
     * @memberof SftConfiguration
     */
    type: string;
    /**
     * 
     * @type {string}
     * @memberof SftConfiguration
     */
    usPharma: string;
    /**
     * 
     * @type {string}
     * @memberof SftConfiguration
     */
    europePharma: string;
    /**
     * 
     * @type {string}
     * @memberof SftConfiguration
     */
    pipetteTesting: string;
    /**
     * 
     * @type {string}
     * @memberof SftConfiguration
     */
    isoCal: string;
    /**
     * 
     * @type {string}
     * @memberof SftConfiguration
     */
    manufacturer: string;
    /**
     * 
     * @type {string}
     * @memberof SftConfiguration
     */
    maxWeight: string;
    /**
     * 
     * @type {string}
     * @memberof SftConfiguration
     */
    weighingRange: string;
    /**
     * 
     * @type {string}
     * @memberof SftConfiguration
     */
    scaleInterval: string;
    /**
     * 
     * @type {string}
     * @memberof SftConfiguration
     */
    verificationScaleInterval: string;
}

/**
 * Check if a given object implements the SftConfiguration interface.
 */
export function instanceOfSftConfiguration(value: object): value is SftConfiguration {
    if (!('model' in value) || value['model'] === undefined) return false;
    if (!('type' in value) || value['type'] === undefined) return false;
    if (!('usPharma' in value) || value['usPharma'] === undefined) return false;
    if (!('europePharma' in value) || value['europePharma'] === undefined) return false;
    if (!('pipetteTesting' in value) || value['pipetteTesting'] === undefined) return false;
    if (!('isoCal' in value) || value['isoCal'] === undefined) return false;
    if (!('manufacturer' in value) || value['manufacturer'] === undefined) return false;
    if (!('maxWeight' in value) || value['maxWeight'] === undefined) return false;
    if (!('weighingRange' in value) || value['weighingRange'] === undefined) return false;
    if (!('scaleInterval' in value) || value['scaleInterval'] === undefined) return false;
    if (!('verificationScaleInterval' in value) || value['verificationScaleInterval'] === undefined) return false;
    return true;
}

export function SftConfigurationFromJSON(json: any): SftConfiguration {
    return SftConfigurationFromJSONTyped(json, false);
}

export function SftConfigurationFromJSONTyped(json: any, ignoreDiscriminator: boolean): SftConfiguration {
    if (json == null) {
        return json;
    }
    return {
        
        'model': json['model'],
        'type': json['type'],
        'usPharma': json['usPharma'],
        'europePharma': json['europePharma'],
        'pipetteTesting': json['pipetteTesting'],
        'isoCal': json['isoCal'],
        'manufacturer': json['manufacturer'],
        'maxWeight': json['maxWeight'],
        'weighingRange': json['weighingRange'],
        'scaleInterval': json['scaleInterval'],
        'verificationScaleInterval': json['verificationScaleInterval'],
    };
}

export function SftConfigurationToJSON(value?: SftConfiguration | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'model': value['model'],
        'type': value['type'],
        'usPharma': value['usPharma'],
        'europePharma': value['europePharma'],
        'pipetteTesting': value['pipetteTesting'],
        'isoCal': value['isoCal'],
        'manufacturer': value['manufacturer'],
        'maxWeight': value['maxWeight'],
        'weighingRange': value['weighingRange'],
        'scaleInterval': value['scaleInterval'],
        'verificationScaleInterval': value['verificationScaleInterval'],
    };
}

