import * as React from "react";
import styles from "./BlackHeaderBar.module.scss";
import PencilMarkIcon from "../../assets/icons/pencil.svg";
import { useHistory } from "react-router-dom";

interface BlackHeaderBarProps {
  text: string;
  hasButton?: boolean;
  urlToNavigate?: string;
}

const BlackHeaderBar: React.FC<BlackHeaderBarProps> = ({
  text,
  hasButton,
  urlToNavigate,
}) => {
  const history = useHistory();

  return (
    <div>
      <button
        className={`text-left ${styles.blackRow} ${
          hasButton ? "" : styles.withoutCursor
        }`}
        type="button"
        data-testid="product-number-button"
        onClick={() => {
          if (hasButton) {
            history.push(urlToNavigate);
          }
        }}
      >
        <span className={styles.headerText}>{text}</span>
        {hasButton && (
          <img
            src={PencilMarkIcon}
            className={styles.pencil}
            alt={"edit"}
            title={"edit"}
          />
        )}
      </button>
    </div>
  );
};

export default BlackHeaderBar;
