import * as React from "react";
import styles from "./LifecycleServices.module.scss";
import { useTranslation } from "react-i18next";
import { CCol, CRow } from "@coreui/react";
import Check from "../../assets/icons/check.svg";
import i18next from "i18next";
import CollapsibleRow from "../CollapsibleRow/CollapsibleRow";

interface LifecycleServicesProps {
  equipmentInstallation: boolean;
  calibrationCertificate: boolean;
  minWeightCertificate: boolean;
  conformityCertificate: boolean;
  calibration: boolean;
  usp: boolean;
  phEur: boolean;
  balanceConformityCertificate: boolean;
  balanceTestProtocol: string;
  repeatabilitMeasurements: string;
  checkOfConformity: boolean;
  model: string;
  isBasicPackage: boolean;
}

const LifecycleServices: React.FC<LifecycleServicesProps> = (props) => {
  const { t } = useTranslation("common");

  const createRow = (
    text: string,
    value: boolean,
    isNested: boolean,
    isLastRow: boolean
  ) => {
    return (
      <CRow>
        <CCol className={styles.textCol} xs="10" lg="8">
          <p className={`${styles.text} ${isNested ? styles.nested : ""}`}>
            {text}
          </p>
        </CCol>
        <CCol
          className={`${styles.valueCol} ${isLastRow ? styles.lastRow : ""}`}
          lg="4"
          xs="2"
        >
          <div className={styles.value}>
            {value ? <img src={Check} /> : <p className={styles.dash}>{"-"}</p>}
          </div>
        </CCol>
      </CRow>
    );
  };

  const rows = [
    "equipmentInstallation",
    "calibrationCertificate",
    "minWeightCertificate",
    "conformityCertificate",
    "calibration",
    "usp",
    "phEur",
  ];

  return (
    <>
      <CollapsibleRow
        mainText={t(
          `step4.recommendedService.secondSection.${
            props.isBasicPackage
              ? "pharmaMaintenancePackageBasic"
              : "pharmaMaintenancePackage"
          }`
        )}
        rightText={props.model}
        showInitially={true}
        children={
          <div className={styles.section}>
            {rows
              .filter((x) => props[x] !== undefined)
              .map((x, index) =>
                createRow(
                  t(`step4.recommendedService.${x}.text`),
                  props[x],
                  i18next.exists(
                    `common:step4.recommendedService.${x}.isNested`
                  ),
                  index === rows.length - 1
                )
              )}
          </div>
        }
      />
      <CRow>
        <CCol className={styles.whiteTextCol} lg="12">
          <p className={`${styles.whiteText}`}>
            {t(`step4.recommendedService.optionalServices.text`)}
          </p>
        </CCol>
      </CRow>
      <CRow className={styles.whiteRow}>
        <CCol
          className={`${styles.whiteTextCol} ${styles.nestedWhite}`}
          xs="12"
          lg="8"
        >
          <p className={`${styles.whiteText}`}>
            {t(`step4.recommendedService.balanceConformityCertificate.text`)}
          </p>
        </CCol>
        <CCol className={`${styles.whiteValueCol}`} xs="12" lg="4">
          {
            <div className={styles.textValue}>
              {props.balanceConformityCertificate ? (
                <img src={Check} />
              ) : (
                <p className={styles.dash}>{"-"}</p>
              )}
            </div>
          }
        </CCol>
      </CRow>
      <CRow className={styles.whiteRow}>
        <CCol
          className={`${styles.whiteTextCol} ${styles.nestedWhite}`}
          xs="12"
          lg="8"
        >
          <p className={`${styles.whiteText}`}>
            {t(`step4.recommendedService.balanceTestProtocol.text`)}
          </p>
        </CCol>
        <CCol className={`${styles.whiteValueCol}`} xs="12" lg="4">
          {
            <div className={styles.textValue}>
              <p className={styles.dash}>
                <strong>{props.balanceTestProtocol}</strong>
              </p>
            </div>
          }
        </CCol>
      </CRow>
      <CRow className={styles.whiteRow}>
        <CCol
          className={`${styles.whiteTextCol} ${styles.nestedWhite}`}
          xs="12"
          lg="8"
        >
          <p className={`${styles.whiteText}`}>
            {t(`step4.recommendedService.repeatabilitMeasurements.text`)}
          </p>
        </CCol>
        <CCol className={`${styles.whiteValueCol}`} lg="4">
          {
            <div className={styles.textValue}>
              <p className={styles.dash}>
                <strong>{props.repeatabilitMeasurements}</strong>
              </p>
            </div>
          }
        </CCol>
      </CRow>
      <CRow className={styles.lastWhiteRow}>
        <CCol
          className={`${styles.whiteTextCol} ${styles.nestedWhite}`}
          xs="12"
          lg="8"
        >
          <p className={`${styles.whiteText}`}>
            {t(`step4.recommendedService.checkOfConformity.text`)}
          </p>
        </CCol>
        <CCol className={`${styles.whiteValueCol}`} xs="12" lg="4">
          {
            <div className={styles.textValue}>
              {props.checkOfConformity ? (
                <img src={Check} style={{width: '20px'}} />
              ) : (
                <p className={styles.dash}>{"-"}</p>
              )}
            </div>
          }
        </CCol>
      </CRow>
    </>
  );
};

export default LifecycleServices;
