import { useEffect, useRef, useState } from "react";
import styles from "./SearchableDropdown.module.scss";
import { useTranslation } from "react-i18next";
import arrow from "../../assets/icons/arrow-down.svg";
import MagnifyingGlass from "../../assets/icons/magnifying-glass.svg";

interface SearchableDropdownProps {
  dropdownOptions: Map<string, string>;
  selectedValue?: [string, string];
  searchPlaceholder: string;
  dropdownPlaceholder: string;
  setValue: (value: string) => void;
}

const SearchableDropdown: React.FC<SearchableDropdownProps> = (
  props
): JSX.Element => {
  const {
    dropdownOptions,
    selectedValue,
    searchPlaceholder,
    dropdownPlaceholder,
    setValue,
  } = props;
  const [searchValue, setSearchValue] = useState<string>("");
  const { t } = useTranslation("common");
  const [isVisible, setIsVisible] = useState(false);
  const [filteredOptions, setFilteredOptions] = useState<[string, string][]>(
    Array.from(dropdownOptions)
  );
  const dropdownRef = useRef(null);
  const dropdownContentRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (isVisible && dropdownContentRef.current) {
      const rect = dropdownContentRef.current.getBoundingClientRect();
      if (rect.bottom > window.innerHeight) {
        dropdownContentRef.current.classList.add(styles.top);
      } else {
        dropdownContentRef.current.classList.remove(styles.top);
      }
    }
  }, [isVisible]);

  useEffect(() => {
    if (searchValue.length === 0) {
      setFilteredOptions(Array.from(dropdownOptions));
    } else {
      setFilteredOptions(
        Array.from(dropdownOptions).filter(([key, value]) =>
          key.toLocaleLowerCase().startsWith(searchValue.toLowerCase())
        )
      );
    }
    // eslint-disable-next-line
  }, [searchValue]);

  return (
    <div className={styles.dropdown} ref={dropdownRef}>
      <button
        onClick={() => {
          setIsVisible(!isVisible);
        }}
        className={`${styles.dropdownButton} ${
          selectedValue ? "" : styles.grayText
        }`}
      >
        {selectedValue ? selectedValue : dropdownPlaceholder}
        <span
          className={`${styles.arrow} ${isVisible ? styles.down : styles.up}`}
        >
          <img src={arrow} alt="arrow" />
        </span>
      </button>
      {
        <div
          className={`${styles.dropdownContent} ${
            isVisible ? styles.displayBlock : styles.displayNone
          }`}
          ref={dropdownContentRef}
        >
          {" "}
          <div className={styles.searchBarWrapper}>
            <img src={MagnifyingGlass} className={styles.icon} />
            <input
              className={styles.searchBar}
              type="text"
              placeholder={searchPlaceholder}
              onChange={(e) => {
                setSearchValue(e.target.value.trim());
              }}
            />
          </div>
          {filteredOptions.map((x) => (
            <label
              onClick={() => {
                setValue(x[1]);
                setIsVisible(false);
              }}
              key={x[0]}
              className={styles.dropdownItem}
            >
              {x[1]}
            </label>
          ))}
        </div>
      }
    </div>
  );
};

export default SearchableDropdown;
