/* tslint:disable */
/* eslint-disable */
/**
 * Calculate assessment API.
 * API for requesting Assessment via Service Finder Tool
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SftConfiguration } from './SftConfiguration';
import {
    SftConfigurationFromJSON,
    SftConfigurationFromJSONTyped,
    SftConfigurationToJSON,
} from './SftConfiguration';

/**
 * 
 * @export
 * @interface InstrumentConfigurations
 */
export interface InstrumentConfigurations {
    /**
     * 
     * @type {Array<SftConfiguration>}
     * @memberof InstrumentConfigurations
     */
    instrumentConfigurations: Array<SftConfiguration>;
}

/**
 * Check if a given object implements the InstrumentConfigurations interface.
 */
export function instanceOfInstrumentConfigurations(value: object): value is InstrumentConfigurations {
    if (!('instrumentConfigurations' in value) || value['instrumentConfigurations'] === undefined) return false;
    return true;
}

export function InstrumentConfigurationsFromJSON(json: any): InstrumentConfigurations {
    return InstrumentConfigurationsFromJSONTyped(json, false);
}

export function InstrumentConfigurationsFromJSONTyped(json: any, ignoreDiscriminator: boolean): InstrumentConfigurations {
    if (json == null) {
        return json;
    }
    return {
        
        'instrumentConfigurations': ((json['instrumentConfigurations'] as Array<any>).map(SftConfigurationFromJSON)),
    };
}

export function InstrumentConfigurationsToJSON(value?: InstrumentConfigurations | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'instrumentConfigurations': ((value['instrumentConfigurations'] as Array<any>).map(SftConfigurationToJSON)),
    };
}

