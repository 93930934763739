/* tslint:disable */
/* eslint-disable */
/**
 * Calculate assessment API.
 * API for requesting Assessment via Service Finder Tool
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ApplicationError,
  AssessmentDTO,
  AssessmentDataCalculatedDTO,
} from '../models/index';
import {
    ApplicationErrorFromJSON,
    ApplicationErrorToJSON,
    AssessmentDTOFromJSON,
    AssessmentDTOToJSON,
    AssessmentDataCalculatedDTOFromJSON,
    AssessmentDataCalculatedDTOToJSON,
} from '../models/index';

export interface RequestAssessmentRequest {
    assessmentDTO: AssessmentDTO;
}

/**
 * 
 */
export class AssessmentApi extends runtime.BaseAPI {

    /**
     * Request an Assessment.
     */
    async requestAssessmentRaw(requestParameters: RequestAssessmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AssessmentDataCalculatedDTO>> {
        if (requestParameters['assessmentDTO'] == null) {
            throw new runtime.RequiredError(
                'assessmentDTO',
                'Required parameter "assessmentDTO" was null or undefined when calling requestAssessment().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/assessment`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AssessmentDTOToJSON(requestParameters['assessmentDTO']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AssessmentDataCalculatedDTOFromJSON(jsonValue));
    }

    /**
     * Request an Assessment.
     */
    async requestAssessment(requestParameters: RequestAssessmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AssessmentDataCalculatedDTO> {
        const response = await this.requestAssessmentRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
