import i18next, { TFunction } from "i18next";
import lang_en from "../i18n/en.json";
import lang_fr from "../i18n/fr.json";
import lang_de from "../i18n/de.json";

const localStorageKey = "sartorius.cellselector.lang";

declare global {
  interface Window {
    setLanguage: (lang: string, save: boolean) => void;
  }
}

class LanguageUtils {
  /**
   * Init language files, load language setting from language param/local storage/browser language. Defaults to "en"
   */
  public static initI18n(): void {
    const resources = {
      en: {
        common: lang_en,
      },
      de: {
        common: lang_de,
      },
      fr: {
        common: lang_fr,
      },
    };

    i18next.init({
      interpolation: { escapeValue: false },
      lng: "en",
      fallbackLng: "en",
      resources,
      react: {
        useSuspense: false,
      },
    });
    this.initLanguage();

    // expose setLanguage, so cypress can use it
    window.setLanguage = (lang: string, save: boolean) =>
      this.setLanguage(lang, save).then((success) => {
        if (success) {
          window.console.log(`language set to ${lang}`);
        } else {
          window.console.error(`failed to change language`);
        }
      });
  }

  /**
   * Set language and save it to localStorage (default)
   * @param lang language ID (e.g. "de")
   * @param save save to localStorage (default: true)
   */
  public static setLanguage(lang: string, save = true): Promise<boolean> {
    return i18next
      .changeLanguage(lang)
      .then(() => {
        if (save) {
          return this.saveLanguage(lang);
        }

        return Promise.resolve(false);
      })
      .catch((e) => Promise.reject(e));
  }

  /**
   * Initializes the language
   * set language by url parameter e.g /Cubis-II-Configurator/de/step1, if not present
   * set language from local storage, if not present
   * set language by browser language
   * @private
   */
  private static initLanguage() {
    const languageParam = window.location.pathname.split("/")[2];
    const languageStored = localStorage.getItem(localStorageKey);
    const browserLanguage = navigator.language;

    if (this.isInitializedLanguage(languageParam)) {
      this.setLanguage(languageParam);
    } else if (languageStored) {
      this.setLanguage(languageStored);
    } else if (this.isInitializedLanguage(browserLanguage)) {
      this.setLanguage(browserLanguage);
    }
  }

  /**
   * Checks if given language has been initialized / is available
   * @private
   */
  private static isInitializedLanguage(lang: string) {
    return Object.keys(i18next.services.resourceStore.data).includes(lang);
  }

  /**
   * Store language locally, to persist between page loads/refreshs
   * @param lang language ID (e.g. "de")
   * @private
   */
  private static async saveLanguage(lang: string): Promise<boolean> {
    // store locally
    localStorage.setItem(localStorageKey, lang);

    return Promise.resolve(false);
  }
}

export const getTranslationArray = (
  t: TFunction,
  prefix: string,
  suffix: string | undefined = undefined
) => {
  const items = [];
  let counter = 0;
  let counterTranslationKey = suffix
    ? `${prefix}.${counter}.${suffix}`
    : `${prefix}.${counter}`;
  let counterTranslationValue = t(counterTranslationKey);
  while (counterTranslationValue !== counterTranslationKey) {
    items.push(counterTranslationValue);
    counter++;
    counterTranslationKey = `${prefix}.${counter}`;
    counterTranslationValue = t(counterTranslationKey);
  }

  return items;
};

export const getTranslationMap = (t: TFunction, prefix: string) => {
  const items = new Map<string, string>();
  let counter = 0;
  let counterKey = `${prefix}.${counter}.key`;
  let counterValue = `${prefix}.${counter}.value`;
  let counterTranslatedKey = t(counterKey);
  let counterTranslatedValue = t(counterValue);
  while (
    counterTranslatedKey !== counterKey ||
    counterTranslatedValue !== counterValue
  ) {
    items.set(counterTranslatedKey, counterTranslatedValue);
    counter++;
    counterKey = `${prefix}.${counter}.key`;
    counterValue = `${prefix}.${counter}.value`;
    counterTranslatedKey = t(counterKey);
    counterTranslatedValue = t(counterValue);
  }

  return items;
};

export default LanguageUtils;
