import React from "react";
import NavigationIcon from "./NavigationIcon";
import styles from "./NavigationStep.module.scss";

export type IconKey = "" | "pencil" | "warning" | "check-alt";

interface NavigationStepProps {
  isCurrentlyEditing: boolean;
  onClick: () => void;
  testid: string;
  disabled?: boolean;
  isCompleted?: boolean;
  shouldShowWarning?: boolean;
  children: any;
}

const NavigationStep: React.FC<NavigationStepProps> = (props): JSX.Element => {
  const {
    isCurrentlyEditing,
    onClick,
    children,
    testid,
    isCompleted,
    shouldShowWarning,
    disabled = false,
  } = props;
  let iconKey: IconKey = "";

  if (isCompleted) {
    iconKey = "check-alt";
  } else if (isCurrentlyEditing) {
    iconKey = "pencil";
  } else if (shouldShowWarning) {
    iconKey = "warning";
  }

  return (
    <div
      className={`${styles.step} ${
        isCurrentlyEditing ? styles.activeStep : styles.inactiveStep
      } ${disabled && styles.disableNextSteps} mr-1 py-0`}
      onClick={onClick}
      data-testid={testid}
    >
      <div className={styles.navigationContentWrapper}>
        <div className={`${styles.iconContainer}`}>
          <NavigationIcon iconKey={iconKey} />
        </div>
        <div className={`${styles.textContainer}`}>{children}</div>
      </div>
    </div>
  );
};

export default NavigationStep;
