import {
    NavigationEvent,
    RiskAssessment,
    Requirements,
    CustomerData,
    Recommendation,
  } from '../types/tagManagerEvents';
  import { NavigationSlugs } from '../types/navigation';
  
  export const sendGoogleTagManagerEvent = (
    event:
      | NavigationEvent
      | RiskAssessment
      | Requirements
      | CustomerData
      | Recommendation
  ): void => {
    if (window && window.dataLayer) {
      window.dataLayer.push(event);
    }
  };
  
  export const trackPageImpression = (step: number): void => {
    const navigationEvent: NavigationEvent = {
      event: 'servicefindertoolImpression',
      'sft-config-name': NavigationSlugs[step],
      'sft-config-step': step,
    };
  
    sendGoogleTagManagerEvent(navigationEvent);
  };
  