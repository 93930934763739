import * as React from "react";
import styles from "./RecommendationRoutineSection.module.scss";
import BlackHeaderBar from "./BlackHeaderBar";
import { useTranslation } from "react-i18next";
import { CCol, CRow } from "@coreui/react";

interface RecommendationRoutineSectionProps {
  subsections: Subsection[];
}

interface Subsection {
  name: string;
  values: ValueAndBackgroundColor[];
}

interface ValueAndBackgroundColor {
  value: string;
  isValueBackgroundGray?: boolean;
}

interface HeaderAndValue extends ValueAndBackgroundColor {
  header: string;
}

const RecommendationRoutineSection: React.FC<
  RecommendationRoutineSectionProps
> = ({ subsections }) => {
  const { t } = useTranslation("common");

  const createSubSection = (
    headersAndValues: HeaderAndValue[],
    withHorizontalLine?: boolean
  ) => {
    return (
      <>
        <div className={styles.desktopSection}>
          <CRow>
            <CCol xs="12" lg="8">
              {headersAndValues.map((x) => (
                <div className={styles.header}>{x.header}</div>
              ))}
            </CCol>
            <CCol xs="12" lg="4">
              {headersAndValues.map((x) => (
                <div
                  className={`${styles.value} ${
                    x.isValueBackgroundGray ? styles.withGrayBackground : ""
                  }`}
                >
                  {x.value}
                </div>
              ))}
            </CCol>
          </CRow>
          {withHorizontalLine && <hr />}
        </div>

        <div className={styles.mobileSection}>
          <CRow>
            <CCol xs="12">
              {headersAndValues.map((x) => (
                <>
                  <div className={styles.header}>{x.header}</div>
                  <div
                    className={`${styles.value} ${
                      x.isValueBackgroundGray ? styles.withGrayBackground : ""
                    }`}
                  >
                    {x.value}
                  </div>
                </>
              ))}
            </CCol>
          </CRow>
          {withHorizontalLine && <hr />}
        </div>
      </>
    );
  };

  return (
    <div>
      <div className={styles.blackHeaderWrapper}>
        <BlackHeaderBar text={t("step4.recommendationRoutine.headerText")} />
      </div>
      <div className={styles.lastRow}>
        {subsections.map((subsection, subsectionIndex) => (
          <>
            <CRow>
              <CCol>
                <h4 className={styles.sectionTitle}>
                  <strong>
                    {t(
                      `step4.recommendationRoutine.${subsection.name}.headerText`
                    )}
                  </strong>
                </h4>
              </CCol>
            </CRow>
            {createSubSection(
              subsection.values.map((x, valueIndex) => ({
                header: t(
                  `step4.recommendationRoutine.${subsection.name}.textRows.${valueIndex}`
                ),
                value: x.value,
                isValueBackgroundGray: x.isValueBackgroundGray,
              })),
              subsectionIndex + 1 !== subsections.length
            )}
          </>
        ))}
      </div>
    </div>
  );
};

export default RecommendationRoutineSection;
