import React from "react";
import { CContainer, CFooter } from "@coreui/react";
import styles from "./Footer.module.scss";
import {
  NavigationButtonDownload,
  NavigationButtonNext,
  NavigationButtonPrevious,
} from "./Navigation/NavigationButtons";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { usePdf } from "../hooks/usePdfApi";

interface FooterProps {
  currentStep: number;
}

export const Footer = ({ currentStep }): React.ReactElement => {
  const { t } = useTranslation("common");
  const history = useHistory();
  const { downloadPdf } = usePdf();
  return (
    <>
      <CContainer>
        <div
          className={`pl-8 ml-auto text-nowrap ${
            styles.bottomNavigationButtonContainer
          } ${currentStep !== 5 ? styles.bottomRightMargin : ""}`}
        >
          {currentStep > 1 && (
            <NavigationButtonPrevious
              height={25}
              width={25}
              data-testid="prev-button"
              onClick={() => {
                history.push(`/step${currentStep - 1}`);
              }}
              label={t("general.back")}
            />
          )}

          {currentStep === 4 ? (
            <NavigationButtonDownload
              height={25}
              width={25}
              label={t("general.download")}
              onClick={async () => {
                await downloadPdf()
              }}
            />
          ) : (
            <NavigationButtonNext
              height={25}
              width={25}
              label={t("general.next")}
              onClick={() => {
                history.push(`/step${currentStep + 1}`);
              }}
            />
          )}
        </div>
      </CContainer>
      <CFooter fixed={false}>
        <CContainer>
          <h3 className={styles.headline}>General Information</h3>
          <ul className={styles.list}>
            <li>
              <a
                href="https://www.sartorius.com/en/imprint"
                target="_blank"
                title="Imprint"
              >
                Imprint
              </a>
            </li>
            <li>
              <a
                href="https://www.sartorius.com/en/disclaimer"
                target="_blank"
                title="Disclaimer"
              >
                Disclaimer
              </a>
            </li>
            <li>
              <a
                href="https://www.sartorius.com/en/general-terms-and-conditions"
                target="_blank"
                title="Terms & Conditions"
              >
                Terms & Conditions
              </a>
            </li>
            <li>
              <a
                href="https://www.sartorius.com/en/legal-documents"
                target="_blank"
                title="Legal Documents"
              >
                Legal Documents
              </a>
            </li>
            <li>
              <a
                href="https://www.sartorius.com/en/data-protection"
                target="_blank"
                title="Data Protection"
              >
                Data Protection
              </a>
            </li>
            <li>
              <a
                href="https://www.sartorius.com/en/cookie-policy"
                target="_blank"
                title="Cookie Policy"
              >
                Cookie Policy
              </a>
            </li>
          </ul>
          <p className={styles.copyright}>
            © {new Date().getFullYear()} Sartorius AG
          </p>
        </CContainer>
      </CFooter>
    </>
  );
};
