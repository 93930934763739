/* tslint:disable */
/* eslint-disable */
/**
 * Calculate assessment API.
 * API for requesting Assessment via Service Finder Tool
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InstallationPackageDTO
 */
export interface InstallationPackageDTO {
    /**
     * 
     * @type {string}
     * @memberof InstallationPackageDTO
     */
    packageNumber: string;
    /**
     * 
     * @type {boolean}
     * @memberof InstallationPackageDTO
     */
    equipmentMaintenance?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InstallationPackageDTO
     */
    accreditedCalibrationCertificate?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InstallationPackageDTO
     */
    calibrationAdditionalWeighing?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InstallationPackageDTO
     */
    conformityCertificate?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InstallationPackageDTO
     */
    minimumWeightCertificate?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InstallationPackageDTO
     */
    testReportUSP?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InstallationPackageDTO
     */
    testReportPhEur?: boolean;
}

/**
 * Check if a given object implements the InstallationPackageDTO interface.
 */
export function instanceOfInstallationPackageDTO(value: object): value is InstallationPackageDTO {
    if (!('packageNumber' in value) || value['packageNumber'] === undefined) return false;
    return true;
}

export function InstallationPackageDTOFromJSON(json: any): InstallationPackageDTO {
    return InstallationPackageDTOFromJSONTyped(json, false);
}

export function InstallationPackageDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): InstallationPackageDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'packageNumber': json['packageNumber'],
        'equipmentMaintenance': json['equipmentMaintenance'] == null ? undefined : json['equipmentMaintenance'],
        'accreditedCalibrationCertificate': json['accreditedCalibrationCertificate'] == null ? undefined : json['accreditedCalibrationCertificate'],
        'calibrationAdditionalWeighing': json['calibrationAdditionalWeighing'] == null ? undefined : json['calibrationAdditionalWeighing'],
        'conformityCertificate': json['conformityCertificate'] == null ? undefined : json['conformityCertificate'],
        'minimumWeightCertificate': json['minimumWeightCertificate'] == null ? undefined : json['minimumWeightCertificate'],
        'testReportUSP': json['testReportUSP'] == null ? undefined : json['testReportUSP'],
        'testReportPhEur': json['testReportPhEur'] == null ? undefined : json['testReportPhEur'],
    };
}

export function InstallationPackageDTOToJSON(value?: InstallationPackageDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'packageNumber': value['packageNumber'],
        'equipmentMaintenance': value['equipmentMaintenance'],
        'accreditedCalibrationCertificate': value['accreditedCalibrationCertificate'],
        'calibrationAdditionalWeighing': value['calibrationAdditionalWeighing'],
        'conformityCertificate': value['conformityCertificate'],
        'minimumWeightCertificate': value['minimumWeightCertificate'],
        'testReportUSP': value['testReportUSP'],
        'testReportPhEur': value['testReportPhEur'],
    };
}

