/* tslint:disable */
/* eslint-disable */
/**
 * Calculate assessment API.
 * API for requesting Assessment via Service Finder Tool
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EquipmentPackageDTO
 */
export interface EquipmentPackageDTO {
    /**
     * 
     * @type {string}
     * @memberof EquipmentPackageDTO
     */
    packageNumber: string;
    /**
     * 
     * @type {boolean}
     * @memberof EquipmentPackageDTO
     */
    equipmentMaintenance?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EquipmentPackageDTO
     */
    documentationIQOQ?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EquipmentPackageDTO
     */
    accreditedCalibrationCertificate?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EquipmentPackageDTO
     */
    calibrationAdditionalWeighing?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EquipmentPackageDTO
     */
    conformityCertificate?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EquipmentPackageDTO
     */
    minimumWeightCertificate?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EquipmentPackageDTO
     */
    testReportUSP?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EquipmentPackageDTO
     */
    testReportPhEur?: boolean;
}

/**
 * Check if a given object implements the EquipmentPackageDTO interface.
 */
export function instanceOfEquipmentPackageDTO(value: object): value is EquipmentPackageDTO {
    if (!('packageNumber' in value) || value['packageNumber'] === undefined) return false;
    return true;
}

export function EquipmentPackageDTOFromJSON(json: any): EquipmentPackageDTO {
    return EquipmentPackageDTOFromJSONTyped(json, false);
}

export function EquipmentPackageDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): EquipmentPackageDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'packageNumber': json['packageNumber'],
        'equipmentMaintenance': json['equipmentMaintenance'] == null ? undefined : json['equipmentMaintenance'],
        'documentationIQOQ': json['documentationIQOQ'] == null ? undefined : json['documentationIQOQ'],
        'accreditedCalibrationCertificate': json['accreditedCalibrationCertificate'] == null ? undefined : json['accreditedCalibrationCertificate'],
        'calibrationAdditionalWeighing': json['calibrationAdditionalWeighing'] == null ? undefined : json['calibrationAdditionalWeighing'],
        'conformityCertificate': json['conformityCertificate'] == null ? undefined : json['conformityCertificate'],
        'minimumWeightCertificate': json['minimumWeightCertificate'] == null ? undefined : json['minimumWeightCertificate'],
        'testReportUSP': json['testReportUSP'] == null ? undefined : json['testReportUSP'],
        'testReportPhEur': json['testReportPhEur'] == null ? undefined : json['testReportPhEur'],
    };
}

export function EquipmentPackageDTOToJSON(value?: EquipmentPackageDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'packageNumber': value['packageNumber'],
        'equipmentMaintenance': value['equipmentMaintenance'],
        'documentationIQOQ': value['documentationIQOQ'],
        'accreditedCalibrationCertificate': value['accreditedCalibrationCertificate'],
        'calibrationAdditionalWeighing': value['calibrationAdditionalWeighing'],
        'conformityCertificate': value['conformityCertificate'],
        'minimumWeightCertificate': value['minimumWeightCertificate'],
        'testReportUSP': value['testReportUSP'],
        'testReportPhEur': value['testReportPhEur'],
    };
}

