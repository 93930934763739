/* tslint:disable */
/* eslint-disable */
/**
 * Calculate assessment API.
 * API for requesting Assessment via Service Finder Tool
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { PageOneDTO } from './PageOneDTO';
import {
    PageOneDTOFromJSON,
    PageOneDTOFromJSONTyped,
    PageOneDTOToJSON,
} from './PageOneDTO';
import type { PageFourDTO } from './PageFourDTO';
import {
    PageFourDTOFromJSON,
    PageFourDTOFromJSONTyped,
    PageFourDTOToJSON,
} from './PageFourDTO';
import type { PageTwoDTO } from './PageTwoDTO';
import {
    PageTwoDTOFromJSON,
    PageTwoDTOFromJSONTyped,
    PageTwoDTOToJSON,
} from './PageTwoDTO';
import type { PageFiveDTO } from './PageFiveDTO';
import {
    PageFiveDTOFromJSON,
    PageFiveDTOFromJSONTyped,
    PageFiveDTOToJSON,
} from './PageFiveDTO';
import type { PageThreeDTO } from './PageThreeDTO';
import {
    PageThreeDTOFromJSON,
    PageThreeDTOFromJSONTyped,
    PageThreeDTOToJSON,
} from './PageThreeDTO';
import type { PageSixDTO } from './PageSixDTO';
import {
    PageSixDTOFromJSON,
    PageSixDTOFromJSONTyped,
    PageSixDTOToJSON,
} from './PageSixDTO';

/**
 * 
 * @export
 * @interface AssessmentDataCalculatedDTO
 */
export interface AssessmentDataCalculatedDTO {
    /**
     * 
     * @type {PageOneDTO}
     * @memberof AssessmentDataCalculatedDTO
     */
    pageOneDTO: PageOneDTO;
    /**
     * 
     * @type {PageTwoDTO}
     * @memberof AssessmentDataCalculatedDTO
     */
    pageTwoDTO: PageTwoDTO;
    /**
     * 
     * @type {PageThreeDTO}
     * @memberof AssessmentDataCalculatedDTO
     */
    pageThreeDTO: PageThreeDTO;
    /**
     * 
     * @type {PageFourDTO}
     * @memberof AssessmentDataCalculatedDTO
     */
    pageFourDTO: PageFourDTO;
    /**
     * 
     * @type {PageFiveDTO}
     * @memberof AssessmentDataCalculatedDTO
     */
    pageFiveDTO: PageFiveDTO;
    /**
     * 
     * @type {PageSixDTO}
     * @memberof AssessmentDataCalculatedDTO
     */
    pageSixDTO: PageSixDTO;
}

/**
 * Check if a given object implements the AssessmentDataCalculatedDTO interface.
 */
export function instanceOfAssessmentDataCalculatedDTO(value: object): value is AssessmentDataCalculatedDTO {
    if (!('pageOneDTO' in value) || value['pageOneDTO'] === undefined) return false;
    if (!('pageTwoDTO' in value) || value['pageTwoDTO'] === undefined) return false;
    if (!('pageThreeDTO' in value) || value['pageThreeDTO'] === undefined) return false;
    if (!('pageFourDTO' in value) || value['pageFourDTO'] === undefined) return false;
    if (!('pageFiveDTO' in value) || value['pageFiveDTO'] === undefined) return false;
    if (!('pageSixDTO' in value) || value['pageSixDTO'] === undefined) return false;
    return true;
}

export function AssessmentDataCalculatedDTOFromJSON(json: any): AssessmentDataCalculatedDTO {
    return AssessmentDataCalculatedDTOFromJSONTyped(json, false);
}

export function AssessmentDataCalculatedDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): AssessmentDataCalculatedDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'pageOneDTO': PageOneDTOFromJSON(json['pageOneDTO']),
        'pageTwoDTO': PageTwoDTOFromJSON(json['pageTwoDTO']),
        'pageThreeDTO': PageThreeDTOFromJSON(json['pageThreeDTO']),
        'pageFourDTO': PageFourDTOFromJSON(json['pageFourDTO']),
        'pageFiveDTO': PageFiveDTOFromJSON(json['pageFiveDTO']),
        'pageSixDTO': PageSixDTOFromJSON(json['pageSixDTO']),
    };
}

export function AssessmentDataCalculatedDTOToJSON(value?: AssessmentDataCalculatedDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'pageOneDTO': PageOneDTOToJSON(value['pageOneDTO']),
        'pageTwoDTO': PageTwoDTOToJSON(value['pageTwoDTO']),
        'pageThreeDTO': PageThreeDTOToJSON(value['pageThreeDTO']),
        'pageFourDTO': PageFourDTOToJSON(value['pageFourDTO']),
        'pageFiveDTO': PageFiveDTOToJSON(value['pageFiveDTO']),
        'pageSixDTO': PageSixDTOToJSON(value['pageSixDTO']),
    };
}

