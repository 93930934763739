import { CRow, CCol } from "@coreui/react";
import React from "react";
import NavigationStep from "./NavigationStep";
import styles from "./NavigationSteps.module.scss";
import stepStyles from "./NavigationStep.module.scss";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useRecoilState } from "recoil";
import { configurationState } from "../../states";

interface NavigationProps {
  currentStep: number;
}

const NavigationSteps: React.FC<NavigationProps> = ({ currentStep }) => {
  const history = useHistory();
  const [configuration] = useRecoilState(configurationState);
  const { t } = useTranslation("common");

  return (
    <>
      <div className={`text-center small pt-1 pb-3`}>
        <CRow>
          <CCol lg="12">
            <div className={styles.navigation}>
              <NavigationStep
                testid="navigation-step1"
                isCurrentlyEditing={currentStep === 1}
                isCompleted={configuration.step1Data.isCompleted}
                shouldShowWarning={false}
                onClick={() => {
                  history.push("/step1");
                }}
              >
                <div>{t("navigationSteps.0.line1")}</div>
              </NavigationStep>

              <NavigationStep
                testid="navigation-step2"
                disabled={!configuration.step1Data.isCompleted}
                isCurrentlyEditing={currentStep === 2}
                isCompleted={configuration.step2Data.isCompleted}
                shouldShowWarning={false}
                onClick={() => {
                  history.push("/step2");
                }}
              >
                <div>{t("navigationSteps.1.line1")}</div>
              </NavigationStep>

              <NavigationStep
                testid="navigation-step3"
                disabled={!configuration.step2Data.isCompleted}
                isCurrentlyEditing={currentStep === 3}
                isCompleted={configuration.step3Data.isCompleted}
                shouldShowWarning={false}
                onClick={() => {
                  history.push("/step3");
                }}
              >
                <div>{t("navigationSteps.2.line1")}</div>
              </NavigationStep>

              <div
                className={`${
                  currentStep === 4
                    ? stepStyles.activeRequestStep
                    : stepStyles.requestStep
                } ${
                  configuration.step3Data.isCompleted
                    ? ""
                    : styles.disabledButton
                }`}
                onClick={() => {
                  history.push("/step4");
                }}
              >
                <div className={stepStyles.navigationContentWrapper}>
                  <div className={stepStyles.textContainer}>
                    {t("navigationSteps.last")}
                  </div>
                </div>
              </div>
            </div>
          </CCol>
        </CRow>
      </div>
    </>
  );
};

export default NavigationSteps;
