/* tslint:disable */
/* eslint-disable */
/**
 * Calculate assessment API.
 * API for requesting Assessment via Service Finder Tool
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ApplicationError,
  AssessmentDTO,
} from '../models/index';
import {
    ApplicationErrorFromJSON,
    ApplicationErrorToJSON,
    AssessmentDTOFromJSON,
    AssessmentDTOToJSON,
} from '../models/index';

export interface RequestPdfRequest {
    assessmentDTO: AssessmentDTO;
}

/**
 * 
 */
export class PdfRequestApi extends runtime.BaseAPI {

    /**
     * Request a new Pdf. Creates a PDF File and returns it.
     */
    async requestPdfRaw(requestParameters: RequestPdfRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters['assessmentDTO'] == null) {
            throw new runtime.RequiredError(
                'assessmentDTO',
                'Required parameter "assessmentDTO" was null or undefined when calling requestPdf().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/pdfrequests`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AssessmentDTOToJSON(requestParameters['assessmentDTO']),
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Request a new Pdf. Creates a PDF File and returns it.
     */
    async requestPdf(requestParameters: RequestPdfRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.requestPdfRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
