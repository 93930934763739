import { Prompt, useHistory } from "react-router-dom";
import ConfigurationWarning from "../ConfigurationWarning/ConfigurationWarning";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";
import { currentStepState } from "../../states";

interface NavigationWarningProps {
  missingFields: string[];
}

export const NavigationWarning: React.FC<NavigationWarningProps> = ({
  missingFields,
}): JSX.Element => {
  const [currentStep] = useRecoilState(currentStepState);
  const [isWarningVisible, setIsWarningVisible] = useState(false);
  const [isProceedingToNextStep, setIsProceedingToNextStep] = useState(false);
  const [stepToProceed, setStepToProceed] = useState(currentStep);
  const { t } = useTranslation("common");
  const history = useHistory();

  useEffect(
    () => {
      if (isProceedingToNextStep) {
        history.push(`/step${stepToProceed}`);
      }
    },
    // eslint-disable-next-line
    [isProceedingToNextStep]
  );

  return (
    <>
      <Prompt
        message={(props) => {
          const stepToProceedNumber = parseInt(
            props.pathname.replace("/step", ""),
            10
          );

          if (isNaN(stepToProceedNumber) || stepToProceedNumber < currentStep) {
            return true;
          }

          const hasMissingFields = missingFields.length > 0;

          setStepToProceed(stepToProceedNumber);
          setIsWarningVisible(hasMissingFields);
          setIsProceedingToNextStep(!hasMissingFields);
          return !hasMissingFields;
        }}
      />
      <ConfigurationWarning
        title={t("warning.title")}
        show={isWarningVisible}
        onClose={() => {
          setIsWarningVisible(false);
        }}
      >
        <p>{t("warning.body")}</p>
        <ul>
          {missingFields.map((x, i) => (
            <li key={`li-${i}`}>
              <small>{x}</small>
            </li>
          ))}
        </ul>
      </ConfigurationWarning>
    </>
  );
};
