import i18next, { TFunction } from "i18next";
import { getTranslationMap } from "./LanguageUtils";
import { Step3Section, Step3Substep } from "../types/Step3Section";

export const getSectionsData = (t: TFunction): Array<Step3Section> => {
  let sectionCounter = 0;
  let substepCounter = 0;
  const sections: Array<Step3Section> = [];

  while (i18next.exists(`common:step3.sections.${sectionCounter}.title`)) {
    sections.push({
      title: t(`step3.sections.${sectionCounter}.title`),
      substeps: [],
    });

    while (
      i18next.exists(
        `common:step3.sections.${sectionCounter}.substeps.${substepCounter}.name`
      )
    ) {
      let dropdownOptions: Map<string, string> = new Map<string, string>();
      if (
        i18next.exists(
          `common:step3.sections.${sectionCounter}.substeps.${substepCounter}.dropdownOptions`
        )
      ) {
        dropdownOptions = getTranslationMap(
          t,
          `step3.sections.${sectionCounter}.substeps.${substepCounter}.dropdownOptions`
        );
      }

      sections[sectionCounter].substeps.push({
        header: t(
          `step3.sections.${sectionCounter}.substeps.${substepCounter}.header`
        ),
        isRequired:
          t(
            `step3.sections.${sectionCounter}.substeps.${substepCounter}.isRequired`
          ) === "true",
        isEmailAddress:
          t(
            `step3.sections.${sectionCounter}.substeps.${substepCounter}.isEmailAddress`
          ) === "true",
        name: t(
          `step3.sections.${sectionCounter}.substeps.${substepCounter}.name`
        ),
        placeholder: t(
          `step3.sections.${sectionCounter}.substeps.${substepCounter}.placeholder`
        ),
        dropdownOptions: dropdownOptions,
      });

      substepCounter++;
    }
    substepCounter = 0;
    sectionCounter++;
  }

  return sections;
};
