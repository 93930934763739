/* tslint:disable */
/* eslint-disable */
/**
 * Calculate assessment API.
 * API for requesting Assessment via Service Finder Tool
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CustomerContactData } from './CustomerContactData';
import {
    CustomerContactDataFromJSON,
    CustomerContactDataFromJSONTyped,
    CustomerContactDataToJSON,
} from './CustomerContactData';
import type { RiskAssessmentData } from './RiskAssessmentData';
import {
    RiskAssessmentDataFromJSON,
    RiskAssessmentDataFromJSONTyped,
    RiskAssessmentDataToJSON,
} from './RiskAssessmentData';
import type { InstrumentConfigurations } from './InstrumentConfigurations';
import {
    InstrumentConfigurationsFromJSON,
    InstrumentConfigurationsFromJSONTyped,
    InstrumentConfigurationsToJSON,
} from './InstrumentConfigurations';

/**
 * 
 * @export
 * @interface PageOneDTO
 */
export interface PageOneDTO {
    /**
     * 
     * @type {CustomerContactData}
     * @memberof PageOneDTO
     */
    customerContactData: CustomerContactData;
    /**
     * 
     * @type {InstrumentConfigurations}
     * @memberof PageOneDTO
     */
    instrumentConfigurations?: InstrumentConfigurations;
    /**
     * 
     * @type {RiskAssessmentData}
     * @memberof PageOneDTO
     */
    riskAssessment?: RiskAssessmentData;
}

/**
 * Check if a given object implements the PageOneDTO interface.
 */
export function instanceOfPageOneDTO(value: object): value is PageOneDTO {
    if (!('customerContactData' in value) || value['customerContactData'] === undefined) return false;
    return true;
}

export function PageOneDTOFromJSON(json: any): PageOneDTO {
    return PageOneDTOFromJSONTyped(json, false);
}

export function PageOneDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): PageOneDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'customerContactData': CustomerContactDataFromJSON(json['customerContactData']),
        'instrumentConfigurations': json['instrumentConfigurations'] == null ? undefined : InstrumentConfigurationsFromJSON(json['instrumentConfigurations']),
        'riskAssessment': json['riskAssessment'] == null ? undefined : RiskAssessmentDataFromJSON(json['riskAssessment']),
    };
}

export function PageOneDTOToJSON(value?: PageOneDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'customerContactData': CustomerContactDataToJSON(value['customerContactData']),
        'instrumentConfigurations': InstrumentConfigurationsToJSON(value['instrumentConfigurations']),
        'riskAssessment': RiskAssessmentDataToJSON(value['riskAssessment']),
    };
}

