/* tslint:disable */
/* eslint-disable */
/**
 * Calculate assessment API.
 * API for requesting Assessment via Service Finder Tool
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AssessmentDTO
 */
export interface AssessmentDTO {
    /**
     * 
     * @type {number}
     * @memberof AssessmentDTO
     */
    impactIncorrectMeasurementsSafety: number;
    /**
     * 
     * @type {number}
     * @memberof AssessmentDTO
     */
    impactIncorrectMeasurementBusiness: number;
    /**
     * 
     * @type {number}
     * @memberof AssessmentDTO
     */
    probabilityIncorrectMeasurement: number;
    /**
     * 
     * @type {number}
     * @memberof AssessmentDTO
     */
    probabilityIncorrectMeasurementUndetected: number;
    /**
     * 
     * @type {string}
     * @memberof AssessmentDTO
     */
    model: string;
    /**
     * 
     * @type {string}
     * @memberof AssessmentDTO
     */
    equipmentQualification: string;
    /**
     * 
     * @type {string}
     * @memberof AssessmentDTO
     */
    fullBalanceMaintenanceRequired: string;
    /**
     * 
     * @type {string}
     * @memberof AssessmentDTO
     */
    usPharmaCompliance: string;
    /**
     * 
     * @type {string}
     * @memberof AssessmentDTO
     */
    europePharmaCompliance: string;
    /**
     * 
     * @type {number}
     * @memberof AssessmentDTO
     */
    smallestNetWeight: number;
    /**
     * 
     * @type {string}
     * @memberof AssessmentDTO
     */
    balanceUnits: string;
    /**
     * 
     * @type {string}
     * @memberof AssessmentDTO
     */
    pipetteTesting: string;
    /**
     * 
     * @type {string}
     * @memberof AssessmentDTO
     */
    company: string;
    /**
     * 
     * @type {string}
     * @memberof AssessmentDTO
     */
    country: string;
    /**
     * 
     * @type {string}
     * @memberof AssessmentDTO
     */
    street: string;
    /**
     * 
     * @type {string}
     * @memberof AssessmentDTO
     */
    addressNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof AssessmentDTO
     */
    zipCode: string;
    /**
     * 
     * @type {string}
     * @memberof AssessmentDTO
     */
    city: string;
    /**
     * 
     * @type {string}
     * @memberof AssessmentDTO
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof AssessmentDTO
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof AssessmentDTO
     */
    emailAddress: string;
    /**
     * 
     * @type {string}
     * @memberof AssessmentDTO
     */
    department?: string;
}

/**
 * Check if a given object implements the AssessmentDTO interface.
 */
export function instanceOfAssessmentDTO(value: object): value is AssessmentDTO {
    if (!('impactIncorrectMeasurementsSafety' in value) || value['impactIncorrectMeasurementsSafety'] === undefined) return false;
    if (!('impactIncorrectMeasurementBusiness' in value) || value['impactIncorrectMeasurementBusiness'] === undefined) return false;
    if (!('probabilityIncorrectMeasurement' in value) || value['probabilityIncorrectMeasurement'] === undefined) return false;
    if (!('probabilityIncorrectMeasurementUndetected' in value) || value['probabilityIncorrectMeasurementUndetected'] === undefined) return false;
    if (!('model' in value) || value['model'] === undefined) return false;
    if (!('equipmentQualification' in value) || value['equipmentQualification'] === undefined) return false;
    if (!('fullBalanceMaintenanceRequired' in value) || value['fullBalanceMaintenanceRequired'] === undefined) return false;
    if (!('usPharmaCompliance' in value) || value['usPharmaCompliance'] === undefined) return false;
    if (!('europePharmaCompliance' in value) || value['europePharmaCompliance'] === undefined) return false;
    if (!('smallestNetWeight' in value) || value['smallestNetWeight'] === undefined) return false;
    if (!('balanceUnits' in value) || value['balanceUnits'] === undefined) return false;
    if (!('pipetteTesting' in value) || value['pipetteTesting'] === undefined) return false;
    if (!('company' in value) || value['company'] === undefined) return false;
    if (!('country' in value) || value['country'] === undefined) return false;
    if (!('street' in value) || value['street'] === undefined) return false;
    if (!('zipCode' in value) || value['zipCode'] === undefined) return false;
    if (!('city' in value) || value['city'] === undefined) return false;
    if (!('firstName' in value) || value['firstName'] === undefined) return false;
    if (!('lastName' in value) || value['lastName'] === undefined) return false;
    if (!('emailAddress' in value) || value['emailAddress'] === undefined) return false;
    return true;
}

export function AssessmentDTOFromJSON(json: any): AssessmentDTO {
    return AssessmentDTOFromJSONTyped(json, false);
}

export function AssessmentDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): AssessmentDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'impactIncorrectMeasurementsSafety': json['impactIncorrectMeasurementsSafety'],
        'impactIncorrectMeasurementBusiness': json['impactIncorrectMeasurementBusiness'],
        'probabilityIncorrectMeasurement': json['probabilityIncorrectMeasurement'],
        'probabilityIncorrectMeasurementUndetected': json['probabilityIncorrectMeasurementUndetected'],
        'model': json['model'],
        'equipmentQualification': json['equipmentQualification'],
        'fullBalanceMaintenanceRequired': json['fullBalanceMaintenanceRequired'],
        'usPharmaCompliance': json['usPharmaCompliance'],
        'europePharmaCompliance': json['europePharmaCompliance'],
        'smallestNetWeight': json['smallestNetWeight'],
        'balanceUnits': json['balanceUnits'],
        'pipetteTesting': json['pipetteTesting'],
        'company': json['company'],
        'country': json['country'],
        'street': json['street'],
        'addressNumber': json['addressNumber'] == null ? undefined : json['addressNumber'],
        'zipCode': json['zipCode'],
        'city': json['city'],
        'firstName': json['firstName'],
        'lastName': json['lastName'],
        'emailAddress': json['emailAddress'],
        'department': json['department'] == null ? undefined : json['department'],
    };
}

export function AssessmentDTOToJSON(value?: AssessmentDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'impactIncorrectMeasurementsSafety': value['impactIncorrectMeasurementsSafety'],
        'impactIncorrectMeasurementBusiness': value['impactIncorrectMeasurementBusiness'],
        'probabilityIncorrectMeasurement': value['probabilityIncorrectMeasurement'],
        'probabilityIncorrectMeasurementUndetected': value['probabilityIncorrectMeasurementUndetected'],
        'model': value['model'],
        'equipmentQualification': value['equipmentQualification'],
        'fullBalanceMaintenanceRequired': value['fullBalanceMaintenanceRequired'],
        'usPharmaCompliance': value['usPharmaCompliance'],
        'europePharmaCompliance': value['europePharmaCompliance'],
        'smallestNetWeight': value['smallestNetWeight'],
        'balanceUnits': value['balanceUnits'],
        'pipetteTesting': value['pipetteTesting'],
        'company': value['company'],
        'country': value['country'],
        'street': value['street'],
        'addressNumber': value['addressNumber'],
        'zipCode': value['zipCode'],
        'city': value['city'],
        'firstName': value['firstName'],
        'lastName': value['lastName'],
        'emailAddress': value['emailAddress'],
        'department': value['department'],
    };
}

