import { useEffect, useState, useRef, ChangeEvent } from "react";
import styles from "./step3.module.scss";
import HelpTextButton from "../components/HelpTextButton";
import { useTranslation } from "react-i18next";
import { getTranslationArray } from "../utils/LanguageUtils";
import { useRecoilState } from "recoil";
import { configurationState, currentStepState } from "../states";
import { NavigationWarning } from "../components/Navigation/NavigationWarning";
import { CCol, CInput, CRow, CSelect } from "@coreui/react";
import { getSectionsData } from "../utils/Step3SectionsUtils";
import { Step3Substep } from "../types/Step3Section";
import { Step3Selections } from "../types/Configuration";
import { useHistory } from "react-router-dom";
import { trackPageImpression } from "../services/googleTagManager";

const Step3: React.FC = () => {
  const history = useHistory();
  const [, setCurrentStep] = useRecoilState(currentStepState);
  const [configuration, setConfiguration] = useRecoilState(configurationState);
  const [step3Data, setStep3Data] = useState<Step3Selections>(
    configuration.step3Data
  );

  const { t } = useTranslation("common");

  const [missingFields, setMissingFields] = useState([]);
  const sectionsData = getSectionsData(t);

  useEffect(() => {
    setConfiguration({
      ...configuration,
      step3Data: {
        ...step3Data,
        isCompleted:
          step3Data.city?.length > 0 &&
          step3Data.company?.length > 0 &&
          step3Data.country?.length > 0 &&
          step3Data.emailAddress?.length > 0 &&
          isValidEmail(configuration.step3Data.emailAddress) &&
          step3Data.firstName?.length > 0 &&
          step3Data.lastName?.length > 0 &&
          step3Data.street?.length > 0 &&
          step3Data.zipCode?.length > 0,
      },
    });

    // eslint-disable-next-line
  }, [
    step3Data.addressNumber,
    step3Data.city,
    step3Data.company,
    step3Data.country,
    step3Data.emailAddress,
    step3Data.firstName,
    step3Data.lastName,
    step3Data.street,
    step3Data.zipCode,
    step3Data.department,
  ]);

  const trackMissingFields = () => {
    let tempMissingFields: string[] = [];
    sectionsData.forEach((section) => {
      section.substeps.forEach((subStep) => {
        if (
          subStep.isEmailAddress &&
          !isValidEmail(configuration.step3Data[subStep.name])
        ) {
          tempMissingFields.push(t("general.emailError"));
        } else if (
          subStep.isRequired &&
          (configuration.step3Data[subStep.name] === undefined ||
            configuration.step3Data[subStep.name].trim().length === 0)
        ) {
          tempMissingFields.push(subStep.header);
        }
      });
    });

    setMissingFields(tempMissingFields);
  };

  useEffect(
    () => {
      if (!configuration.step2Data.isCompleted) {
        history.push("/step2");
        return;
      }

      setCurrentStep(3);
      trackMissingFields();
      trackPageImpression(3);

    },
    // eslint-disable-next-line
    []
  );

  useEffect(() => {
    trackMissingFields();
    // eslint-disable-next-line
  }, [configuration]);

  const helpTextArray = getTranslationArray(t, "step3.help");

  const getSections = () => {
    return sectionsData.map((sectionData, i) => (
      <div className={i + 1 === sectionsData.length ? "" : styles.section}>
        <div>
          <h2 className={styles.topTitle}>{sectionData.title}</h2>
        </div>
        {sectionData.substeps.map(
          (substepData, i) =>
            i % 2 === 0 && (
              <CRow key={`row-${i}`}>
                <CCol key={`col-${i}`} xs="12" lg="6">
                  {getComponent(sectionData.substeps[i])}
                </CCol>
                {i + 1 < sectionData.substeps.length && (
                  <CCol key={`row-${i + 1}`} xs="12" lg="6">
                    {getComponent(sectionData.substeps[i + 1])}
                  </CCol>
                )}
              </CRow>
            )
        )}
      </div>
    ));
  };

  const isValidEmail = (email: string): boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const getComponent = (substep: Step3Substep) => {
    return substep.dropdownOptions.size > 0 ? (
      <>
        <span className={styles.elementHeader} key={`span-${substep.name}`}>
          {substep.header}
          {substep.isRequired ? "*" : ""}
        </span>
        <CSelect
          id={`ddwn-${substep.name}`}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setStep3Data({
              ...step3Data,
              [substep.name]: e.target.value,
            });
          }}
          key={substep.name}
          className={`${styles.singleSelect} ${styles.mainElement}`}
          defaultValue={
            step3Data[substep.name]?.length > 0 ? step3Data[substep.name] : ""
          }
        >
          <option value="" disabled>
            {substep.placeholder}
          </option>
          {Array.from(substep.dropdownOptions.keys()).map((k) => {
            return (
              <option value={substep.dropdownOptions.get(k)} key={k}>
                {substep.dropdownOptions.get(k)}
              </option>
            );
          })}
        </CSelect>
      </>
    ) : (
      <>
        <span className={styles.elementHeader} key={`span-${substep.name}`}>
          {substep.header}
          {substep.isRequired ? "*" : ""}
        </span>
        <CInput
          key={`inp-${substep.name}`}
          data-testid="text-input"
          type={"text"}
          id={substep.name}
          name={substep.name}
          className={`${styles.textbox} ${styles.mainElement}`}
          value={step3Data[substep.name]}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setStep3Data({
              ...step3Data,
              [substep.name]: e.target.value,
            });
          }}
          placeholder={substep.placeholder}
        />
      </>
    );
  };

  return (
    <>
      <NavigationWarning missingFields={missingFields} />
      <HelpTextButton
        title={t(`step1.help.title`)}
        header={t("general.needHelp")}
        content={
          <div>
            {helpTextArray.map((x, i) => (
              <p key={`help-${i}`}>{x}</p>
            ))}
          </div>
        }
      />
      {getSections()}
      <div className={styles.footerTextWrapper}>
        <span className={styles.footerText}>{t("step3.footerText")}</span>
      </div>
    </>
  );
};

export default Step3;
