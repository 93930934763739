/* tslint:disable */
/* eslint-disable */
/**
 * Calculate assessment API.
 * API for requesting Assessment via Service Finder Tool
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Information about the customer that is requesting a quote
 * @export
 * @interface CustomerContactData
 */
export interface CustomerContactData {
    /**
     * First name of the customer
     * @type {string}
     * @memberof CustomerContactData
     */
    firstName: string;
    /**
     * Last name of the customer
     * @type {string}
     * @memberof CustomerContactData
     */
    lastName: string;
    /**
     * Email of the customer
     * @type {string}
     * @memberof CustomerContactData
     */
    email: string;
    /**
     * Telephone number of the customer
     * @type {string}
     * @memberof CustomerContactData
     */
    phone: string;
    /**
     * Company of the customer
     * @type {string}
     * @memberof CustomerContactData
     */
    company: string;
    /**
     * Country of the customer
     * @type {string}
     * @memberof CustomerContactData
     */
    country: string;
    /**
     * State of the customer (optional)
     * @type {string}
     * @memberof CustomerContactData
     */
    state?: string;
    /**
     * Industry of the customer
     * @type {string}
     * @memberof CustomerContactData
     */
    industry: string;
    /**
     * Street of the customer
     * @type {string}
     * @memberof CustomerContactData
     */
    street: string;
    /**
     * Address number of the customer
     * @type {string}
     * @memberof CustomerContactData
     */
    addressNumber?: string;
    /**
     * City of the customer
     * @type {string}
     * @memberof CustomerContactData
     */
    city: string;
    /**
     * Zip Code/Postal code of the customer
     * @type {string}
     * @memberof CustomerContactData
     */
    zipcode: string;
    /**
     * How many units would you like to buy?
     * @type {string}
     * @memberof CustomerContactData
     */
    units?: string;
    /**
     * Comments of the customer
     * @type {string}
     * @memberof CustomerContactData
     */
    comments?: string;
    /**
     * Customer wants to get a newsletter
     * @type {boolean}
     * @memberof CustomerContactData
     */
    newsletter?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CustomerContactData
     */
    utmTrackingUrl?: string;
    /**
     * Industry Focus of the customer
     * @type {string}
     * @memberof CustomerContactData
     */
    industryFocus: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerContactData
     */
    timelineOfPurchase?: string;
}

/**
 * Check if a given object implements the CustomerContactData interface.
 */
export function instanceOfCustomerContactData(value: object): value is CustomerContactData {
    if (!('firstName' in value) || value['firstName'] === undefined) return false;
    if (!('lastName' in value) || value['lastName'] === undefined) return false;
    if (!('email' in value) || value['email'] === undefined) return false;
    if (!('phone' in value) || value['phone'] === undefined) return false;
    if (!('company' in value) || value['company'] === undefined) return false;
    if (!('country' in value) || value['country'] === undefined) return false;
    if (!('industry' in value) || value['industry'] === undefined) return false;
    if (!('street' in value) || value['street'] === undefined) return false;
    if (!('city' in value) || value['city'] === undefined) return false;
    if (!('zipcode' in value) || value['zipcode'] === undefined) return false;
    if (!('industryFocus' in value) || value['industryFocus'] === undefined) return false;
    return true;
}

export function CustomerContactDataFromJSON(json: any): CustomerContactData {
    return CustomerContactDataFromJSONTyped(json, false);
}

export function CustomerContactDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomerContactData {
    if (json == null) {
        return json;
    }
    return {
        
        'firstName': json['firstName'],
        'lastName': json['lastName'],
        'email': json['email'],
        'phone': json['phone'],
        'company': json['company'],
        'country': json['country'],
        'state': json['state'] == null ? undefined : json['state'],
        'industry': json['industry'],
        'street': json['street'],
        'addressNumber': json['addressNumber'] == null ? undefined : json['addressNumber'],
        'city': json['city'],
        'zipcode': json['zipcode'],
        'units': json['units'] == null ? undefined : json['units'],
        'comments': json['comments'] == null ? undefined : json['comments'],
        'newsletter': json['newsletter'] == null ? undefined : json['newsletter'],
        'utmTrackingUrl': json['utmTrackingUrl'] == null ? undefined : json['utmTrackingUrl'],
        'industryFocus': json['industry_focus'],
        'timelineOfPurchase': json['timeline_of_purchase'] == null ? undefined : json['timeline_of_purchase'],
    };
}

export function CustomerContactDataToJSON(value?: CustomerContactData | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'firstName': value['firstName'],
        'lastName': value['lastName'],
        'email': value['email'],
        'phone': value['phone'],
        'company': value['company'],
        'country': value['country'],
        'state': value['state'],
        'industry': value['industry'],
        'street': value['street'],
        'addressNumber': value['addressNumber'],
        'city': value['city'],
        'zipcode': value['zipcode'],
        'units': value['units'],
        'comments': value['comments'],
        'newsletter': value['newsletter'],
        'utmTrackingUrl': value['utmTrackingUrl'],
        'industry_focus': value['industryFocus'],
        'timeline_of_purchase': value['timelineOfPurchase'],
    };
}

